import React, { useEffect, useState } from 'react';
import { fetchWikiContent } from '../../services/cmsService';

const WikiArticle = ({ articleId }) => {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadArticle = async () => {
      try {
        setLoading(true);
        const data = await fetchWikiContent();
        const foundArticle = data.find((item) => item.name === articleId);
        if (!foundArticle) {
          throw new Error('Articolul nu a fost găsit.');
        }
        setArticle(foundArticle);
      } catch (err) {
        setError(err.message || 'A apărut o eroare.');
      } finally {
        setLoading(false);
      }
    };

    loadArticle();
  }, [articleId]);

  if (loading) {
    return <p className="text-center text-gray-500">Se încarcă...</p>;
  }

  if (error) {
    return (
      <div className="text-center text-gray-500">
        <h2 className="text-2xl font-semibold">Eroare</h2>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <>
      {article && (
        <>
          {/* Schema.org Metadata */}
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'Article',
                headline: article.title,
                articleSection: article.subcategory,
                url: `${process.env.REACT_APP_BASE_URL}/wiki/${article.name}`,
                description: article.content.substring(0, 150),
                author: {
                  '@type': 'Organization',
                  name: 'Go4Projects',
                },
                publisher: {
                  '@type': 'Organization',
                  name: 'Go4Projects',
                  logo: {
                    '@type': 'ImageObject',
                    url: `${process.env.REACT_APP_BASE_URL}/assets/logo.png`,
                  },
                },
              }),
            }}
          />

          {/* Content */}
          <article className="prose prose-blue max-w-full">
            <h1 className="text-4xl font-bold text-gray-800 mb-3">
              {article.title}
            </h1>
            <h2 className="text-lg text-gray-500 mb-6">
              {article.subcategory}
            </h2>
            <div dangerouslySetInnerHTML={{ __html: article.content }} />
          </article>
        </>
      )}
    </>
  );
};

export default WikiArticle;
