import { React, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Header from './components/Header/Header';
import Licitatii from './pages/Licitatii/Licitatii';
import AuctionDetails from './pages/AuctionDetails/AuctionDetails';

import Projects from './pages/Projects/Projects';
import ProjectDetail from './pages/ProjectDetail/ProjectDetail';

import DirectAwards from './pages/DirectAwards/DirectAwards';
import DirectAwardDetails from './pages/DirectAwardDetails/DirectAwardDetails';

import Footer from './components/Footer/Footer';
import NotFoundComponent from './components/NotFoundComponent';
import CookiesPolicy from './components/CookiesPolicy';
import Home from './pages/Home/Home';
import Subscriptions from './pages/Subscriptions/Subscriptions';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import UserAccount from './pages/UserAccount/UserAccount';
import WikiPage from './pages/Wiki/WikiPage';
import Contact from './pages/Contact/Contact';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ConfirmEmail from './pages/ConfirmEmail/ConfirmEmail';
import Success from './pages/Success/Success';
import RouteLogger from './components/RouteLogger';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import NetworkStatus from './components/NetworkStatus/NetworkStatus';
import CSPConfig from './components/CSPConfig/CSPConfig';

const App = () => {
  const isDevelopment = process.env.REACT_APP_PRODUCTION === 'development';
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // Check if token exists on mount
  useEffect(() => {
    const token = Cookies.get('jwtToken');
    if (token) {
      setIsAuthenticated(true); // Token exists, user is authenticated
    }
  }, []);

  return (
    <HelmetProvider>
      <CSPConfig />
      <Router>
        <RouteLogger enabled={!isDevelopment} />
        <NetworkStatus />
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/licitatii/" element={<Licitatii />} />
            <Route path="/licitatii/:noticeNo" element={<AuctionDetails />} />
            <Route path="/achizitii-directe/" element={<DirectAwards />} />
            <Route
              path="/achizitii-directe/:noticeNo"
              element={<DirectAwardDetails />}
            />
            <Route path="/proiecte/" element={<Projects />} />
            <Route path="/proiecte/:id" element={<ProjectDetail />} />
            <Route
              path="/abonamente/"
              element={
                <Subscriptions setIsAuthenticated={setIsAuthenticated} />
              }
            />
            <Route path="/login/" element={<Login />} />
            <Route path="/register/" element={<Register />} />
            <Route
              path="/account"
              element={<UserAccount setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route path="/success" element={<Success />} />
            <Route path="/wiki" element={<WikiPage />} />
            <Route path="/wiki/:articleId" element={<WikiPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/confirm-email" element={<ConfirmEmail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/404" element={<NotFoundComponent />} />
            <Route path="*" element={<NotFoundComponent />} />
          </Routes>
          <div className="p-4">
            <CookiesPolicy />
          </div>
        </main>
        <Footer />
      </Router>
    </HelmetProvider>
  );
};

export default App;
