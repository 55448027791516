import React, { useState } from 'react';

const PolicyModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {/* Button to open modal */}
      <button
        className="text-white bg-indigo-500 hover:bg-indigo-600 border border-indigo-500 focus:ring-4 focus:ring-indigo-300 font-medium rounded text-lg px-4 lg:px-6 py-2 lg:py-2 sm:mr-2 lg:mr-0 dark:bg-indigo-500 dark:hover:bg-indigo-700 focus:outline-none dark:focus:ring-indigo-800 hover:shadow-white duration-300"
        onClick={() => setIsOpen(true)}
      >
        Politica de cookie
      </button>

      {isOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          onClick={() => setIsOpen(false)}
        >
          <div
            className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-md mx-auto relative overflow-y-auto max-h-[75vh]"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Close button */}
            <div className="absolute top-2 right-2 flex space-x-2">
              <button
                className="text-gray-600 hover:text-gray-800 focus:outline-none"
                onClick={() => setIsOpen(false)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              <button
                className="text-gray-600 hover:text-gray-800 focus:outline-none"
                onClick={() => alert('Additional Action')}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M3 14h18M3 6h18" />
                </svg>
              </button>
            </div>

            {/* Modal content */}
            <h2 className="text-xl font-bold mb-4 text-gray-800">Politica de Cookie</h2>
            <p className="text-gray-600 mb-4">
              Această politică de cookie explică ce sunt cookie-urile și cum le utilizăm pe platforma {process.env.REACT_APP_NAME}. Te rugăm să citești cu atenție această politică pentru a înțelege ce tipuri de cookie-uri utilizăm, informațiile colectate și modul în care acestea sunt folosite.
            </p>
            <p className="text-gray-600 mb-4">
              <strong>Ce sunt cookie-urile?</strong> Cookie-urile sunt fișiere mici de text stocate pe dispozitivul tău atunci când vizitezi un site web. Acestea permit site-ului să rețină informații despre vizita ta, cum ar fi preferințele sau activitatea anterioară.
            </p>
            <p className="text-gray-600 mb-4">
              <strong>Tipuri de cookie-uri pe care le folosim:</strong>
              <ul className="list-disc pl-6 text-gray-600">
                <li><strong>Cookie-uri esențiale:</strong> Necesare pentru funcționarea corectă a platformei.</li>
                <li><strong>Cookie-uri de performanță:</strong> Ajută la analizarea utilizării site-ului pentru a îmbunătăți experiența utilizatorilor.</li>
                <li><strong>Cookie-uri de funcționalitate:</strong> Rețin preferințele tale pentru a oferi o experiență personalizată.</li>
                <li><strong>Cookie-uri de marketing:</strong> Utilizate pentru a afișa anunțuri relevante în funcție de interesele tale.</li>
              </ul>
            </p>
            <p className="text-gray-600 mb-4">
              <strong>Gestionarea cookie-urilor:</strong> Poți controla cookie-urile din setările browserului tău. Totuși, dezactivarea anumitor cookie-uri poate afecta funcționalitatea platformei.
            </p>
            <p className="text-gray-600 mb-4">
              Pentru orice întrebări suplimentare despre utilizarea cookie-urilor, te rugăm să ne contactezi la adresa cont [at] domeniu [dot] com.
            </p>

            {/* Close button inside modal */}
            <button
              className="bg-blue-600 text-white font-medium py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mt-4 w-full"
              onClick={() => setIsOpen(false)}
            >
              Închide
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PolicyModal;
