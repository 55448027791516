import React from 'react';

const EmailAlerts = ({ userData, setUserData, emailAlertsEdit, setEmailAlertsEdit, handleEmailAlertsSave }) => {
    return (
        <div className="p-6 grid bg-white shadow-md rounded-lg col-span-1">
            <h2 className="text-2xl font-bold border-b-2 pb-2 mb-4">
                Alerte pe Email
            </h2>
            {emailAlertsEdit ? (
                <div>
                    <div className="mb-4">
                        <label className="block mb-2">
                            Primește alerte pe email:
                        </label>
                        <input
                            type="checkbox"
                            checked={userData?.emailAlerts || false}
                            onChange={(e) =>
                                setUserData((prev) => ({
                                    ...prev,
                                    emailAlerts: e.target.checked,
                                }))
                            }
                            className="h-5 w-5"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-2">Tipuri de alerte:</label>
                        <select
                            multiple
                            value={userData?.alertTypes || []}
                            onChange={(e) =>
                                setUserData((prev) => ({
                                    ...prev,
                                    alertTypes: Array.from(
                                        e.target.selectedOptions,
                                        (option) => option.value,
                                    ),
                                }))
                            }
                            className="border p-2 w-full"
                        >
                            <option value="NewTender">Licitație nouă</option>
                            <option value="DeadlineReminder">
                                Reminder termen limită
                            </option>
                            <option value="AwardNotification">
                                Notificare atribuire
                            </option>
                            <option value="UpdateOnWatched">
                                Actualizare licitații urmărite
                            </option>
                        </select>
                    </div>
                    <button
                        onClick={handleEmailAlertsSave}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    >
                        Salvează
                    </button>
                    <button
                        onClick={() => setEmailAlertsEdit(false)}
                        className="bg-red-500 text-white px-4 py-2 ml-4 rounded hover:bg-red-600"
                    >
                        Anulează
                    </button>
                </div>
            ) : (
                <div>
                    <p>
                        <strong>Primește alerte pe email:</strong>{' '}
                        {userData?.emailAlerts ? 'Da' : 'Nu'}
                    </p>
                    <p>
                        <strong>Tipuri de alerte:</strong>{' '}
                        {userData?.alertTypes?.length
                            ? userData.alertTypes.join(', ')
                            : 'Nicio alertă selectată'}
                    </p>
                    <button
                        onClick={() => setEmailAlertsEdit(true)}
                        className="bg-gray-500 text-white px-4 py-2 mt-4 rounded hover:bg-gray-600"
                    >
                        Modifică
                    </button>
                </div>
            )}
        </div>
    );
};

export default EmailAlerts;