import React, { useState } from 'react';

const TermsModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {/* Button to open modal */}
      <button
        className="text-white bg-indigo-500 hover:bg-indigo-600 border border-indigo-500 focus:ring-4 focus:ring-indigo-300 font-medium rounded text-lg px-4 lg:px-6 py-2 lg:py-2 sm:mr-2 lg:mr-0 dark:bg-indigo-500 dark:hover:bg-indigo-700 focus:outline-none dark:focus:ring-indigo-800 hover:shadow-white duration-300"
        onClick={() => setIsOpen(true)}
      >
        Termeni și Condiții
      </button>

      {isOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          onClick={() => setIsOpen(false)}
        >
          <div
            className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-md mx-auto relative overflow-y-auto max-h-[75vh]"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Close button */}
            <div className="absolute top-2 right-2 flex space-x-2">
              <button
                className="text-gray-600 hover:text-gray-800 focus:outline-none"
                onClick={() => setIsOpen(false)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              <button
                className="text-gray-600 hover:text-gray-800 focus:outline-none"
                onClick={() => alert('Additional Action')}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M3 14h18M3 6h18" />
                </svg>
              </button>
            </div>

            {/* Modal content */}
            <h2 className="text-xl font-bold mb-4 text-gray-800">Termeni și Condiții</h2>
            <p className="text-gray-600 mb-4">
              Acești termeni și condiții reglementează utilizarea platformei {process.env.REACT_APP_NAME}, deținută de SC BIMART SOLUTIONS SRL.
              Prin accesarea și utilizarea platformei, sunteți de acord să respectați termenii și condițiile descrise mai jos.
            </p>
            <p className="text-gray-600 mb-4">
              <strong>1. Utilizarea Platformei</strong><br />
              Platforma este destinată exclusiv utilizării în scopuri legale. Orice utilizare abuzivă sau acces neautorizat este strict interzisă.
            </p>
            <p className="text-gray-600 mb-4">
              <strong>2. Proprietatea Intelectuală</strong><br />
              Toate materialele prezente pe platformă, inclusiv texte, imagini, logo-uri și coduri, sunt proprietatea SC BIMART SOLUTIONS SRL.
              Este interzisă reproducerea, distribuirea sau utilizarea acestora fără acordul scris al companiei.
            </p>
            <p className="text-gray-600 mb-4">
              <strong>3. Limitarea Răspunderii</strong><br />
              SC BIMART SOLUTIONS SRL nu poate fi trasă la răspundere pentru eventualele daune sau pierderi rezultate din utilizarea platformei.
            </p>
            <p className="text-gray-600 mb-4">
              <strong>4. Modificarea Termenilor</strong><br />
              SC BIMART SOLUTIONS SRL îy rezervă dreptul de a modifica termenii și condițiile fără notificare prealabilă.
              Orice modificări vor fi publicate pe această pagină.
            </p>
            <p className="text-gray-600 mb-4">
              <strong>5. Contact</strong><br />
              Pentru întrrebări legate de termeni și condiții, ne puteți contacta la: cont [at] domeniu [dot] com.
            </p>

            {/* Close button inside modal */}
            <button
              className="bg-blue-600 text-white font-medium py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mt-4 w-full"
              onClick={() => setIsOpen(false)}
            >
              Închide
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TermsModal;
