import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchWikiContent } from '../../services/cmsService';
import WikiArticle from './WikiArticle';
import SeoPageComponent from '../../components/SeoPageComponent/SeoPageComponent';
import Breadcrumbs from '../../components/Breadcrumbs';

const WikiPage = () => {
    const { articleId } = useParams();
    const navigate = useNavigate();
    const [wikiContent, setWikiContent] = useState([]);
    const [selectedArticleId, setSelectedArticleId] = useState(articleId || null);
    const [loading, setLoading] = useState(true);

    // Fetch Wiki Content
    useEffect(() => {
        const loadContent = async () => {
            try {
                setLoading(true);
                const data = await fetchWikiContent();
                setWikiContent(data);
                if (!articleId && data.length > 0) {
                    setSelectedArticleId(null);
                }
            } catch (error) {
                console.error('Eroare la încărcarea conținutului Wiki:', error);
            } finally {
                setLoading(false);
            }
        };
        loadContent();
    }, [articleId]);

    // Gruparea articolelor pe subcategorii
    const groupedContent = wikiContent.reduce((acc, article) => {
        acc[article.subcategory] = acc[article.subcategory] || [];
        acc[article.subcategory].push(article);
        return acc;
    }, {});

    // Articolul selectat
    const selectedArticle = wikiContent.find(
        (article) => article.name === selectedArticleId,
    );

    // Generare SEO
    const pageTitle = selectedArticle
        ? `${selectedArticle.title} - Wiki`
        : 'Wiki - Descoperă articole interesante';
    const pageDescription = selectedArticle
        ? `Află mai multe despre ${selectedArticle.title}, un articol din categoria ${selectedArticle.subcategory}.`
        : 'Explorează articole interesante despre construcții, proiecte și multe altele pe Wiki.';
    const pageUrl = selectedArticle
        ? `${process.env.REACT_APP_BASE_URL}/wiki/${selectedArticle.name}`
        : `${process.env.REACT_APP_BASE_URL}/wiki`;
    const logoUrl = `${process.env.REACT_APP_BASE_URL}/assets/logo.png`;

    // Selectarea unui articol
    const handleSelectArticle = (id) => {
        navigate(`/wiki/${id}`);
        setSelectedArticleId(id);
    };

    return (
        <>
            {/* SEO */}
            <SeoPageComponent
                pageTitle={pageTitle}
                pageDescription={pageDescription}
                pageUrl={pageUrl}
                logoUrl={logoUrl}
                keywords="wiki, articole, construcții, informații, Go4Projects"
            />

            {/* Breadcrumbs */}
            <Breadcrumbs
                breadcrumbLinks={[
                    { href: '/', label: 'Home' },
                    { href: '/wiki', label: 'Wiki' },
                    ...(selectedArticleId
                        ? [{ label: selectedArticle?.title || 'Articol necunoscut' }]
                        : []),
                ]}
            />

            <div className="min-h-screen bg-gray-50 flex flex-col lg:flex-row mt-5 shadow-lg m-4 border border-indigo-500">
                {/* Sidebar */}
                <aside className="lg:w-1/4 bg-white shadow-md border-b lg:border-b-0 lg:border-r border-gray-200">
                    <h2 className="text-2xl font-bold text-white py-4 text-center bg-indigo-500">Pagini Wiki</h2>
                    {loading ? (
                        <>
                            <div className='p-4 animate-pulse'>
                                <div className="mb-4 border py-2 px-3 rounded-md shadow-md">
                                    <div className="h-5 max-w-48 rounded bg-gray-500 animate-pulse">
                                    </div>
                                    <div className="h-3 rounded bg-blue-400 animate-pulse mt-4 mb-2">
                                    </div>
                                    <div className="h-3 rounded max-w-64 bg-blue-300 animate-pulse">
                                    </div>
                                </div>
                            </div>

                            <div className='p-4'>
                                <div className="mb-4 border py-2 px-3 rounded-md shadow-md">
                                    <div className="h-5 max-w-48 rounded bg-gray-500 animate-pulse">
                                    </div>
                                    <div className="h-3 rounded bg-blue-400 animate-pulse mt-4 mb-2">
                                    </div>
                                    <div className="h-3 rounded max-w-64 bg-blue-300 animate-pulse">
                                    </div>
                                </div>
                            </div>

                            <div className='p-4'>
                                <div className="mb-4 border py-2 px-3 rounded-md shadow-md">
                                    <div className="h-5 max-w-48 rounded bg-gray-500 animate-pulse">
                                    </div>
                                    <div className="h-3 rounded bg-blue-400 animate-pulse mt-4 mb-2">
                                    </div>
                                    <div className="h-3 rounded max-w-64 bg-blue-300 animate-pulse">
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : <div className='p-4'>
                        {
                            Object.keys(groupedContent).map((subcategory) => (
                                <div key={subcategory} className="mb-4 border py-2 px-3 rounded-md hover:shadow-md hover:shadow-indigo-100 hover:border hover:border-indigo-500 duration-300">
                                    <h3 className="text-lg font-semibold text-gray-600">
                                        {subcategory}
                                    </h3>
                                    <ul className="space-y-2 mt-2">
                                        {groupedContent[subcategory].map((article) => (
                                            <li key={article.name}>
                                                <button
                                                    onClick={() => handleSelectArticle(article.name)}
                                                    className={`block text-base text-left w-full ${selectedArticleId === article.name
                                                        ? 'text-blue-800 font-semibold'
                                                        : 'text-blue-600'
                                                        } hover:text-blue-800 hover:underline`}
                                                >
                                                    {article.title}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))
                        }
                    </div>}
                </aside>

                {/* Main Content */}
                <main className="flex-1 p-4 lg:p-6">
                    {selectedArticleId ? (
                        <div className="bg-white shadow rounded-lg p-6 h-full">
                            <WikiArticle articleId={selectedArticleId} />
                        </div>
                    ) : (
                        <div className="bg-white shadow rounded-lg p-6">
                            <h1 className="text-4xl font-bold text-gray-800 mb-4">
                                Bine ai venit pe Wiki
                            </h1>
                            <p className="text-lg text-gray-600">
                                Explorează articolele noastre despre construcții, proiecte și
                                multe altele. Selectează un articol din meniul din stânga pentru
                                a începe!
                            </p>
                        </div>
                    )}
                </main>
            </div>
        </>
    );
};

export default WikiPage;
