class ADVForView {
  constructor(data) {
    this.value = data.Value;
    this.contractAuthorityName = data.ContractAuthorityName;
    this.advertisingNoticeId = data.AdvertisingNoticeId;
    this.contractingAuthorityId = data.ContractingAuthorityId;
    this.assignedUser = data.AssignedUser
      ? new AssignedUser(data.AssignedUser)
      : null;
    this.noticeNo = data.NoticeNo;
    this.cpvCode = data.CpvCode ? new CpvCode(data.CpvCode) : null;
    this.estimatedValue = data.EstimatedValue;
    this.maxEstimatedValue = data.MaxEstimatedValue;
    this.minEstimatedValue = data.MinEstimatedValue;
    this.currencyId = data.CurrencyId;
    this.noticeEntityAddressId = data.NoticeEntityAddressId;
    this.contractObject = data.ContractObject;
    this.contractDescription = data.ContractDescription;
    this.contractRelatedConditions = data.ContractRelatedConditions;
    this.awardCriteria = data.AwardCriteria;
    this.parentAdvertisingNoticeId = data.ParentAdvertisingNoticeId;
    this.documentID = data.DocumentID;
    this.documentUrl = data.DocumentUrl;
    this.documentName = data.DocumentName;
    this.documentUniqueIdentificationCode =
      data.DocumentUniqueIdentificationCode;
    this.sysNoticeStateId = data.SysNoticeStateId;
    this.sysNoticeState = data.SysNoticeState
      ? new SysNoticeState(data.SysNoticeState)
      : null;
    this.sysAdvertisingNoticeId = data.SysAdvertisingNoticeId;
    this.sysAdvertisingNotice = data.SysAdvertisingNotice
      ? new SysAdvertisingNotice(data.SysAdvertisingNotice)
      : null;
    this.publicationDate = data.PublicationDate;
    this.versionPublicationDate = data.VersionPublicationDate;
    this.sysAcquisitionContractType = data.SysAcquisitionContractType
      ? new SysAcquisitionContractType(data.SysAcquisitionContractType)
      : null;
    this.sysAcquisitionContractTypeId = data.SysAcquisitionContractTypeId;
    this.currency = data.Currency ? new Currency(data.Currency) : null;
    this.participationConditions = data.ParticipationConditions;
    this.tenderReceiptDeadline = data.TenderReceiptDeadline;
    this.additionalInformation = data.AdditionalInformation;
    this.noticeEntityAddress = data.NoticeEntityAddress
      ? new NoticeEntityAddressForADV(data.NoticeEntityAddress)
      : null;
    this.createDate = data.CreateDate;
    this.cpvCodeAndName = data.CpvCodeAndName;
  }
}

class NoticeEntityAddressForADV {
  constructor(data) {
    this.officialName = data.OfficialName;
    this.address = data.Address;
    this.city = data.City;
    this.cityItem = data.CityItem ? new CommonEntityType(data.CityItem) : null;
    this.postalCode = data.PostalCode;
    this.postalCodeItem = data.PostalCodeItem
      ? new PostalCodeItem(data.PostalCodeItem)
      : null;
    this.countryID = data.CountryID;
    this.country = data.Country;
    this.countryItem = data.CountryItem
      ? new CommonEntityType(data.CountryItem)
      : null;
    this.county = data.County ? new CommonEntityType(data.County) : null;
    this.email = data.Email;
    this.phone = data.Phone;
    this.fax = data.Fax;
    this.contactPoints = data.ContactPoints;
    this.attentionTo = data.AttentionTo;
    this.caMainAddressUrl = data.CaMainAddressUrl;
    this.buyerProfileUrl = data.BuyerProfileUrl;
    this.nutsCodeID = data.NutsCodeID;
    this.nutsCode = data.NutsCode;
    this.nutsCodeItem = data.NutsCodeItem
      ? new EntityItem(data.NutsCodeItem)
      : null;
    this.contactPerson = data.ContactPerson;
    this.internetAddressesUrl = data.InternetAddressesUrl || [];
    this.electronicInfoAccessUrl = data.ElectronicInfoAccessUrl;
    this.electronicDocumentsSendingUrl = data.ElectronicDocumentsSendingUrl;
    this.isSME = data.IsSME;
    this.sysNoticeEntityTypeID = data.SysNoticeEntityTypeID;
    this.noticeEntityAddressId = data.NoticeEntityAddressId;
    this.entityId = data.EntityId;
    this.entityItem = data.EntityItem ? new EntityItem(data.EntityItem) : null;
    this.isPublishingAgreed = data.IsPublishingAgreed;
    this.nationalIDNumber = data.NationalIDNumber;
    this.contactUserId = data.ContactUserId;
    this.legalBuyerType = data.LegalBuyerType
      ? new CommonEntityType(data.LegalBuyerType)
      : null;
    this.legalBuyerTypeName = data.LegalBuyerTypeName;
  }
}

class AllVersions {
  constructor(data) {
    this.total = data.Total;
    this.items = data.Items
      ? data.Items.map((item) => new GetAllVersionsItem(item))
      : [];
    this.searchTooLong = data.SearchTooLong;
    this.showAssociationButton = data.ShowAssociationButton;
  }
}

class GetAllVersionsItem {
  constructor(data) {
    this.contractingAuthorityName = data.ContractingAuthorityName;
    this.contractingAuthorityFiscalNumber =
      data.ContractingAuthorityFiscalNumber;
    this.advertisingNoticeId = data.AdvertisingNoticeId;
    this.noticeNo = data.NoticeNo;
    this.contractObject = data.ContractObject;
    this.searchRonEstimatedValue = data.SearchRonEstimatedValue;
    this.sysAcquisitionContractType = data.SysAcquisitionContractType
      ? new SysAcquisitionContractType(data.SysAcquisitionContractType)
      : null;
    this.sysAdvertisingNotice = data.SysAdvertisingNotice
      ? new SysAdvertisingNotice(data.SysAdvertisingNotice)
      : null;
    this.cpvCode = data.CpvCode ? new CpvCode(data.CpvCode) : null;
    this.cpvCodeAndName = data.CpvCodeAndName;
    this.publicationDate = data.PublicationDate;
    this.versionPublicationDate = data.VersionPublicationDate;
    this.tenderReceiptDeadline = data.TenderReceiptDeadline;
    this.createDate = data.CreateDate;
    this.cpvCategories = data.CpvCategories || [];
    this.value = data.Value;
    this.currencyCode = data.CurrencyCode;
    this.estimatedValueStr = data.EstimatedValueStr;
    this.documentCode = data.DocumentCode;
    this.documentName = data.DocumentName;
    this.documentID = data.DocumentID;
    this.documentUrl = data.DocumentUrl;
    this.sysNoticeState = data.SysNoticeState
      ? new SysNoticeState(data.SysNoticeState)
      : null;
  }
}

class AssignedUser {
  constructor(data) {
    this.id = data.Id;
    this.text = data.Text;
    this.localeKey = data.LocaleKey;
  }
}

class CpvCode {
  constructor(data) {
    this.id = data.Id;
    this.text = data.Text;
    this.localeKey = data.LocaleKey;
  }
}

class SysNoticeState {
  constructor(data) {
    this.id = data.Id;
    this.text = data.Text;
    this.localeKey = data.LocaleKey;
  }
}

class SysAdvertisingNotice {
  constructor(data) {
    this.id = data.Id;
    this.text = data.Text;
    this.localeKey = data.LocaleKey;
  }
}

class SysAcquisitionContractType {
  constructor(data) {
    this.id = data.Id;
    this.text = data.Text;
    this.localeKey = data.LocaleKey;
  }
}

class Currency {
  constructor(data) {
    this.id = data.Id;
    this.text = data.Text;
    this.localeKey = data.LocaleKey;
  }
}

class CommonEntityType {
  constructor(data) {
    this.id = data.Id;
    this.text = data.Text;
    this.localeKey = data.LocaleKey;
  }
}

class PostalCodeItem {
  constructor(data) {
    this.value = data.Value;
  }
}

class EntityItem {
  constructor(data) {
    this.value = data.Value;
  }
}

export { ADVForView, AllVersions };
