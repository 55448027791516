import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getProjectById } from '../../services/projectService';
import GanttChart from '../../components/GanttChart/GanttChart';
import {
  CalendarDays,
  BadgeEuro,
  Clock3,
  FileDown,
  FileScan,
  Building2,
  ListOrdered,
} from 'lucide-react';
import html2pdf from 'html2pdf.js';
import { saveAs } from 'file-saver';

const ProjectDetail = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const printRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getProjectById(id);
      setProject(data);
    };
    fetchData();
  }, [id]);

  const handleDownloadReport = () => {
    if (project?.reportUrl) {
      saveAs(project.reportUrl, `raport_proiect_${project.id}.pdf`);
    }
  };

  const handleExportPDF = () => {
    if (printRef.current) {
      html2pdf()
        .set({ margin: 0.5, filename: `detalii_proiect_${project.id}.pdf` })
        .from(printRef.current)
        .save();
    }
  };

  if (!project) {
    return (
      <p className="text-center py-10 text-gray-600">
        Se încarcă detaliile proiectului...
      </p>
    );
  }

  const allProcedures = [
    ...(project.extensions || []),
    ...(project.futureProcedures || []),
  ];

  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 py-10 space-y-10">
      <header className="flex flex-col md:flex-row justify-between md:items-center border-b pb-6 gap-4">
        <h1 className="text-3xl font-bold text-indigo-700 leading-tight">
          {project.projectTitle}
        </h1>
        <div className="flex flex-wrap gap-3">
          <button
            onClick={handleDownloadReport}
            className="flex items-center gap-2 bg-emerald-600 text-white px-4 py-2 rounded hover:bg-emerald-700 transition"
          >
            <FileDown size={18} /> Descarcă raport
          </button>
          <button
            onClick={handleExportPDF}
            className="flex items-center gap-2 bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition"
          >
            <FileScan size={18} /> Export PDF
          </button>
        </div>
      </header>

      <section ref={printRef} className="space-y-10">
        <section className="bg-white p-6 rounded-lg shadow border">
          <h2 className="text-lg font-semibold text-gray-800 mb-4 flex items-center gap-2">
            <Building2 size={18} className="text-indigo-600" /> Autoritate
            Contractantă
          </h2>
          <p className="text-base text-gray-700 mb-2">
            {project.authorityName}
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
            <div className="bg-gray-50 p-4 rounded-lg border">
              <p className="text-sm text-gray-500 mb-1 flex items-center gap-2">
                <BadgeEuro size={18} className="text-green-600" /> Valoare
                estimată totală
              </p>
              <p className="text-xl font-semibold text-gray-800">
                {project.estimatedValue?.toLocaleString('ro-RO')} RON
              </p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg border">
              <p className="text-sm text-gray-500 mb-1 flex items-center gap-2">
                <Clock3 size={18} className="text-blue-600" /> Durată execuție
              </p>
              <p className="text-xl font-semibold text-gray-800">
                {project.durationExecution} luni
              </p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg border">
              <p className="text-sm text-gray-500 mb-1 flex items-center gap-2">
                <CalendarDays size={18} className="text-purple-600" /> Perioadă
                estimată
              </p>
              <p className="text-xl font-semibold text-gray-800">
                {project.startDate} – {project.endDate}
              </p>
            </div>
          </div>
        </section>

        <section className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <h2 className="text-xl font-bold text-gray-800 mb-4">
              Gantt: Proceduri
            </h2>
            <div className="w-full overflow-x-auto rounded border">
              <GanttChart procedures={allProcedures} />
            </div>
          </div>

          <aside>
            <h2 className="text-xl font-bold text-gray-800 mb-4 flex items-center gap-2">
              <ListOrdered size={20} className="text-indigo-600" /> Proceduri
            </h2>
            <div className="space-y-4">
              {project.extensions.map((proc, i) => (
                <div
                  key={i}
                  className="border border-gray-200 rounded-xl shadow-sm p-4 bg-white hover:shadow-md transition-all"
                >
                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <div>
                      <h3 className="text-base font-semibold text-gray-900 mb-1">
                        {proc.procedureTitle}
                      </h3>
                      <p className="text-sm text-gray-500 italic">
                        {new Date(proc.startDate).toLocaleDateString('ro-RO')} –{' '}
                        {new Date(proc.endDate).toLocaleDateString('ro-RO')}
                      </p>
                    </div>
                    <span className="inline-block bg-indigo-100 text-indigo-700 text-xs font-semibold px-3 py-1 rounded-full mt-2 sm:mt-0">
                      Etapa {i + 1}
                    </span>
                  </div>
                </div>
              ))}
              {project.futureProcedures?.map((proc, i) => (
                <div
                  key={`f-${i}`}
                  className="border border-dashed border-indigo-300 rounded-xl shadow-sm p-4 bg-indigo-50 hover:shadow-md transition-all"
                >
                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <div>
                      <h3 className="text-base font-semibold text-indigo-800 mb-1">
                        {proc.procedureTitle}
                      </h3>
                      <p className="text-sm text-indigo-600 italic">
                        {new Date(proc.startDate).toLocaleDateString('ro-RO')} –{' '}
                        {new Date(proc.endDate).toLocaleDateString('ro-RO')}
                      </p>
                    </div>
                    <span className="inline-block bg-white text-indigo-700 text-xs font-semibold px-3 py-1 rounded-full border border-indigo-400 mt-2 sm:mt-0">
                      Estimată
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </aside>
        </section>
      </section>
    </section>
  );
};

export default ProjectDetail;
