import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const CONTACT_FORM_API_URL = `${BASE_URL}/api/CMS/SubmitForm`;

/**
 * Trimite datele formularului de contact către backend
 * @param {Object} formData - Obiectul cu datele formularului
 * @returns {Promise<Object>} - Răspunsul de la server
 */
export const contactForm = async (formData) => {
  try {
    const response = await axios.post(CONTACT_FORM_API_URL, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Eroare la trimiterea formularului de contact:', error);
    throw new Error(
      error.response?.data?.message || 'A apărut o eroare la trimiterea formularului.'
    );
  }
};

/**
 * Preia conținutul Wiki din backend.
 * @returns {Promise<Array>} - Lista articolelor Wiki.
 */
export const fetchWikiContent = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/cms/GetWikiContent`);
      return response.data;
    } catch (error) {
      console.error("Eroare la preluarea conținutului Wiki:", error);
      throw error;
    }
  };