import React from 'react';
import './Footer.css';
import logo from '../../assets/icon.png';
import PolicyModal from '../PolicyModal/PolicyModal';
import TermsModal from '../TermsModal/TermsModal';
import contactPoint from '../../constants/contactPoint';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white">
      <div className="max-w-[1320px] mx-auto px-4 py-16">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          {/* Logo and description */}
          <div>
            <img src={logo} alt="Logo" className="w-36 mb-4" />
            <p className="text-gray-400">
              {process.env.REACT_APP_NAME} este primul portal de achiziții
              publice dedicat proiectelor de construcții, oferind transparență
              și acces facil la procedurile de achiziții, de la planificare până
              la publicarea în SEAP și integrarea datelor în platformă.
            </p>
          </div>

          {/* Links */}
          <div className="md:ml-8">
            <h3 className="text-lg font-semibold mb-4">Link-uri</h3>
            <ul className="space-y-2">
              <li>
                <a href="/" className="text-gray-300 hover:text-white">
                  Licitatii
                </a>
              </li>
              <li>
                <a href="/" className="text-gray-300 hover:text-white">
                  Proiecte
                </a>
              </li>
              <li>
                <a href="/" className="text-gray-300 hover:text-white">
                  Abonamente
                </a>
              </li>
            </ul>
          </div>

          {/* Legal */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li>
                <PolicyModal />
              </li>
              <li>
                <TermsModal />
              </li>
            </ul>
          </div>

          {/* Contact */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact</h3>
            <p className="flex items-center mb-2 text-sm text-gray-300">
              <i className="fas fa-building mr-2"></i>{' '}
              {contactPoint.companyName}
            </p>
            <p className="flex items-center mb-2 text-sm text-gray-300">
              <i className="fas fa-hashtag mr-2"></i> Cod Unic de Înregistrare
              {contactPoint.taxIdentificationNumber}
            </p>
            <p className="flex items-center mb-2 text-sm text-gray-300">
              Nr. Înmatriculare {contactPoint.registrationNumber}
            </p>
            <br />
            <p className="flex items-center mb-2 text-sm text-gray-300">
              <i className="fas fa-map-marker-alt mr-2"></i>{' '}
              {contactPoint.address}
            </p>
            <p className="flex items-center mb-2 text-sm text-gray-300">
              <i className="fas fa-phone-alt mr-2"></i>
              <a href="tel:+40722405744" className="hover:text-white">
                {contactPoint.phone}
              </a>
            </p>
            <p className="flex items-center text-sm text-gray-300">
              <i className="fas fa-envelope mr-2"></i>{' '}
              {contactPoint.displayEmail}
            </p>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="bg-gray-700 py-4 text-center text-gray-400 mt-8">
        <p>
          &copy; {new Date().getFullYear()} {process.env.REACT_APP_NAME}. All
          rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
