import React from 'react';

const ListLoadingHolder = () => {
    return (
        <>
            <div className="auction-item space-y-4">
                <div className="h-7 max-w-52 rounded bg-blue-400 animate-pulse">
                </div>
                <div className="h-9 max-w-3xl rounded bg-gray-600 animate-pulse">
                </div>
                <div className="h-6 max-w-lg rounded bg-gray-400 animate-pulse">
                </div>
                <div className="flex items-center justify-between gap-5 flex-wrap">
                    <div className="h-5 w-72 rounded bg-gray-400 animate-pulse"></div>
                    <div className="h-4 w-56 rounded bg-gray-400 animate-pulse"></div>
                </div>
            </div>

            <div className="auction-item space-y-4">
                <div className="h-7 max-w-52 rounded bg-blue-400 animate-pulse">
                </div>
                <div className="h-9 max-w-3xl rounded bg-gray-600 animate-pulse">
                </div>
                <div className="h-6 max-w-lg rounded bg-gray-400 animate-pulse">
                </div>
                <div className="flex items-center justify-between gap-5 flex-wrap">
                    <div className="h-5 w-72 rounded bg-gray-400 animate-pulse"></div>
                    <div className="h-4 w-56 rounded bg-gray-400 animate-pulse"></div>
                </div>
            </div>

            <div className="auction-item space-y-4">
                <div className="h-7 max-w-52 rounded bg-blue-400 animate-pulse">
                </div>
                <div className="h-9 max-w-3xl rounded bg-gray-600 animate-pulse">
                </div>
                <div className="h-6 max-w-lg rounded bg-gray-400 animate-pulse">
                </div>
                <div className="flex items-center justify-between gap-5 flex-wrap">
                    <div className="h-5 w-72 rounded bg-gray-400 animate-pulse"></div>
                    <div className="h-4 w-56 rounded bg-gray-400 animate-pulse"></div>
                </div>
            </div>
        </>
    );
};

export default ListLoadingHolder;