import React, { useState, useEffect, useCallback } from 'react';
import SeoPageComponent from '../../components/SeoPageComponent/SeoPageComponent';
import './Projects.css';
import { fetchProjects } from '../../services/projectService';
import logo from '../../assets/icon.png';
import Breadcrumbs from '../../components/Breadcrumbs';
import ListLoadingHolder from '../../components/LoadingHolders/ListLoadingHolder';
import ProjectCard from '../../components/ProjectCard/ProjectCard';

const filterLabels = {
  projectTitle: {
    label: 'Denumire Proiect',
    placeholder: 'Introduceți titlul proiectului',
  },
  authorityName: {
    label: 'Autoritate contractantă',
    placeholder: 'Introduceți numele autorității',
  },
  procedureTitle: {
    label: 'Denumire procedură',
    placeholder: 'Introduceți o procedură din proiect',
  },
  periodStart: { label: 'Perioada început', placeholder: 'Data start' },
  periodEnd: { label: 'Perioada sfârșit', placeholder: 'Data sfârșit' },
};

const Projects = () => {
  const [filters, setFilters] = useState({
    projectTitle: '',
    authorityName: '',
    procedureTitle: '',
    periodStart: '',
    periodEnd: '',
    pageIndex: 0,
    pageSize: 10,
  });

  const [results, setResults] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [errors, setErrors] = useState([]);

  const validateFilters = useCallback(() => {
    const validationErrors = [];
    setErrors(validationErrors);
    return validationErrors.length === 0;
  }, []);

  const handleFilter = useCallback(
    async (pageIndex = filters.pageIndex) => {
      if (!validateFilters()) return;
      setLoading(true);
      try {
        const data = await fetchProjects({ ...filters, pageIndex });
        setResults(data.results);
        setTotalResults(data.totalResults);
      } catch (error) {
        console.error('Eroare la căutarea proiectelor:', error);
      } finally {
        setLoading(false);
      }
    },
    [filters, validateFilters],
  );

  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleReset = () => {
    const resetFilters = {
      projectTitle: '',
      authorityName: '',
      procedureTitle: '',
      periodStart: '',
      periodEnd: '',
      pageIndex: 0,
      pageSize: 10,
    };
    setFilters(resetFilters);
    setResults([]);
    setTotalResults(0);
    setErrors([]);
    handleFilter(0);
  };

  const handlePageChange = (newPageIndex) => {
    setFilters((prev) => ({ ...prev, pageIndex: newPageIndex }));
    handleFilter(newPageIndex);
  };

  const breadcrumbLinks = [{ href: '/', label: 'Home' }, { label: 'Proiecte' }];
  const pageTitle = `Proiecte - ${process.env.REACT_APP_NAME}`;
  const pageDescription = `Explorează proiectele publice structurate pe proceduri. Filtrare după autoritate, denumire și perioadă.`;
  const pageKeywords = `proiecte publice, achiziții, proceduri, autoritate, CPV`;
  const pageUrl = `https://${process.env.REACT_APP_DOMAIN}/proiecte`;

  return (
    <>
      <SeoPageComponent
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageKeywords={pageKeywords}
        pageUrl={pageUrl}
        logoUrl={logo}
      />

      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />

      <section className="bg-white py-8 section">
        <div className="container">
          <div className="bg-gray-100 p-4 rounded-lg shadow-md">
            <button
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              className="w-full bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 focus:outline-none flex justify-between items-center"
            >
              {isFilterOpen ? 'Ascunde filtrele ▲' : 'Afișează filtrele ▼'}
            </button>

            {isFilterOpen && (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-4">
                {Object.keys(filters)
                  .filter((k) => !['pageIndex', 'pageSize'].includes(k))
                  .map((filter) => (
                    <div key={filter} className="mb-2">
                      <label className="block text-gray-600 mb-2">
                        {filterLabels[filter]?.label || filter}
                      </label>
                      <input
                        type={filter.includes('period') ? 'date' : 'text'}
                        name={filter}
                        value={filters[filter]}
                        onChange={handleInputChange}
                        placeholder={filterLabels[filter]?.placeholder || ''}
                        className="block w-full px-4 py-2 border border-gray-300 rounded focus:outline-none"
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>

          {errors.length > 0 && (
            <div className="mt-4 bg-red-100 border border-red-300 text-red-700 p-4 rounded">
              <ul className="list-disc ml-5 text-sm">
                {errors.map((err, idx) => (
                  <li key={idx}>{err}</li>
                ))}
              </ul>
            </div>
          )}

          <div className="shadow-xl rounded-lg p-5 border mt-10">
            <div className="flex items-center justify-end space-x-4 border-b pb-5">
              <button
                onClick={() => handleFilter()}
                className="bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700"
              >
                Filtrează
              </button>
              <button
                onClick={handleReset}
                className="bg-red-100 hover:bg-red-300 text-gray-700 px-4 py-2 rounded-lg hover:text-white duration-300"
              >
                Resetează
              </button>
            </div>

            <div className="mt-8 space-y-6">
              {loading ? (
                <ListLoadingHolder />
              ) : results.length === 0 ? (
                <p className="text-center text-gray-600">
                  Niciun proiect găsit.
                </p>
              ) : (
                results.map((project) => (
                  <ProjectCard key={project.id} project={project} />
                ))
              )}
            </div>

            {totalResults > filters.pageSize && (
              <div className="mt-6 flex justify-center">
                <div className="space-x-2">
                  {Array.from({
                    length: Math.ceil(totalResults / filters.pageSize),
                  }).map((_, index) => (
                    <button
                      key={index}
                      onClick={() => handlePageChange(index)}
                      className={`px-3 py-1 rounded ${
                        index === filters.pageIndex
                          ? 'bg-indigo-600 text-white'
                          : 'bg-gray-200 hover:bg-gray-300'
                      }`}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;
