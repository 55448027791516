import axios from 'axios';
import Cookies from 'js-cookie';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const API_ENDPOINTS = {
  GET_PLANS: `${BASE_URL}/api/subscription/Plans`,
  GET_USER_SUBSCRIPTION: `${BASE_URL}/api/subscription/UserSubscription`,
  CHECKOUT_SUBSCRIPTION: `${BASE_URL}/api/subscription/CheckOut`,
  CANCEL_SUBSCRIPTION: `${BASE_URL}/api/subscription/CancelSubscriptionPlan`,
  ACTIVATE_SUBSCRIPTION: `${BASE_URL}/api/subscription/SubscriptionPlan`,
};

/**
 * Returnează un header cu token pentru autentificare
 * @returns {Object} - Headers pentru axios
 */
const getAuthHeaders = () => {
  const token = Cookies.get('jwtToken');
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};

const getBasicHeaders = () => {
  return {
    'Content-Type': 'application/json',
  };
};

/**
 * Obține lista abonamentelor disponibile
 * @returns {Promise<Object>} - Răspunsul de la server
 */
export const getPlans = async () => {
  try {
    const response = await axios.get(API_ENDPOINTS.GET_PLANS, {
      headers: getBasicHeaders(),
    });

    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea planurilor de abonament:', error);
    throw new Error(
      error.response?.data?.message ||
        'A apărut o eroare la preluarea abonamentelor.',
    );
  }
};

/**
 * Obține lista abonamentelor utilizatorului
 * @returns {Promise<Array>} - Lista abonamentelor utilizatorului
 */
export const getUserSubscriptions = async () => {
  try {
    const userId = Cookies.get('userId');
    if (!userId) throw new Error('User ID is missing');

    const response = await axios.get(API_ENDPOINTS.GET_USER_SUBSCRIPTION, {
      params: { userId },
      headers: getAuthHeaders(),
    });

    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea abonamentelor utilizatorului:', error);
    throw new Error(
      error.response?.data?.message ||
        'A apărut o eroare la preluarea abonamentelor.',
    );
  }
};

/**
 * Anulează abonamentul unui utilizator
 * @returns {Promise<void>}
 */
export const cancelSubscription = async (userId, token) => {
  try {
    //const userId = Cookies.getItem('userId');
    if (!userId) throw new Error('User ID is missing');

    await axios.put(
      `${API_ENDPOINTS.CANCEL_SUBSCRIPTION}/${userId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  } catch (error) {
    console.error('Eroare la anularea abonamentului:', error);
    throw new Error(
      error.response?.data?.message ||
        'A apărut o eroare la anularea abonamentului.',
    );
  }
};

/**
 * Inițiază procesul de checkout pentru abonament
 * @param {string} userId - ID-ul utilizatorului
 * @param {string} planId - ID-ul planului
 * @returns {Promise<Object>} - URL-ul de checkout
 */
export const checkoutSubscription = async (userId, planId) => {
  try {
    const response = await axios.post(
      `${API_ENDPOINTS.CHECKOUT_SUBSCRIPTION}/${userId}/${planId}`,
      {},
      { headers: getAuthHeaders() },
    );
    return response.data;
  } catch (error) {
    console.error('Eroare la inițierea checkout-ului abonamentului:', error);
    throw new Error(
      error.response?.data?.message ||
        'A apărut o eroare la inițierea checkout-ului.',
    );
  }
};

/**
 * Confirmă dacă abonamentul a fost plătit cu succes
 * @param {string} paymentIntentId - ID-ul intenției de plată
 * @returns {Promise<Object>} - Rezultatul confirmării
 */
export const confirmSubscriptionPayment = async (paymentIntentId) => {
  try {
    const response = await axios.get(
      `${API_ENDPOINTS.CONFIRM_PAYMENT}/${paymentIntentId}`,
      { headers: getAuthHeaders() },
    );
    return response.data;
  } catch (error) {
    console.error('Eroare la confirmarea plății abonamentului:', error);
    throw new Error(
      error.response?.data?.message ||
        'A apărut o eroare la confirmarea plății abonamentului.',
    );
  }
};

/**
 * Activează abonamentul după succesul plății
 * @param {string} userId - ID-ul utilizatorului
 * @param {string} sessionId - ID-ul sesiunii Stripe
 * @param {string} subscriptionId - ID-ul abonamentului
 * @returns {Promise<Object>} - Răspunsul serverului
 */
export const activateSubscription = async (
  userId,
  sessionId,
  subscriptionId,
) => {
  try {
    const token = Cookies.get('jwtToken');

    const response = await axios.post(
      `${API_ENDPOINTS.ACTIVATE_SUBSCRIPTION}/${userId}/${sessionId}/${subscriptionId}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } },
    );

    return response.data;
  } catch (error) {
    console.error('Eroare la activarea abonamentului:', error);
    throw new Error(
      error.response?.data?.message ||
        'A apărut o eroare la activarea abonamentului.',
    );
  }
};
