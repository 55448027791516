import React, { useEffect, useState } from 'react';
import {
  getUserSubscriptions,
  cancelSubscription,
} from '../../services/subscriptionService';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import Cookies from 'js-cookie';

const MySubscriptions = ({ setIsAuthenticated }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const data = await getUserSubscriptions();
        setSubscriptions(
          data.length > 0
            ? data
            : [
                {
                  id: 'freemium',
                  planName: 'Abonament Freemium',
                  description: 'Acces gratuit la funcționalitățile de bază.',
                  paidAmount: '0',
                  currency: 'RON',
                  currentPeriodStart: new Date(),
                  currentPeriodEnd: new Date(),
                },
              ],
        );
      } catch (err) {
        setError('Failed to load subscriptions. Please try again.');
        console.error(err);
      }
    };
    fetchSubscriptions();
  }, [setIsAuthenticated]);

  const handleRefund = async (subscriptionId) => {
    if (subscriptionId === 'freemium') return;
    const token = Cookies.get('jwtToken');
    const userId = Cookies.get('userId');
    try {
      await cancelSubscription(userId, token);
      setSuccess('Subscription canceled successfully!');
      // alert('Subscription canceled successfully!'); // 🔹 Comentat

      Cookies.remove('token');
      Cookies.remove('user');
      Cookies.remove('subscriptionType');
      setIsAuthenticated(false);
      navigate('/login');

      setSubscriptions((prev) =>
        prev.filter((sub) => sub.id !== subscriptionId),
      );
    } catch (err) {
      setError('Refund failed. Please try again.');
      // alert('Refund failed. Please try again.'); // 🔹 Comentat
      console.error(err);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h3 className="text-center text-2xl font-bold mb-4">
        My Subscribed Plan
      </h3>

      {/* 🔹 Afișare mesaj de succes */}
      {success && (
        <div className="text-green-500 text-center mb-4">{success}</div>
      )}

      {/* 🔹 Afișare mesaj de eroare */}
      {error && <div className="text-red-500 text-center mb-4">{error}</div>}

      <div className="flex flex-col gap-4">
        {subscriptions.map((subscription) => (
          <div
            key={subscription.id}
            className="bg-white p-4 rounded shadow-md w-full"
          >
            <h5 className="text-lg font-semibold">{subscription.planName}</h5>
            <p>
              <strong>Description:</strong> {subscription.description}
            </p>
            <p>
              <strong>Paid Amount:</strong> {subscription.currency}{' '}
              {subscription.paidAmount}
            </p>
            <p>
              <strong>Start Date:</strong>{' '}
              {format(new Date(subscription.currentPeriodStart), 'dd MMM yyyy')}
            </p>
            <p>
              <strong>End Date:</strong>{' '}
              {format(new Date(subscription.currentPeriodEnd), 'dd MMM yyyy')}
            </p>
            {subscription.id !== 'freemium' && (
              <button
                className="bg-red-500 text-white px-4 py-2 mt-4 rounded hover:bg-red-600"
                onClick={() => handleRefund(subscription.id)}
                disabled={new Date(subscription.currentPeriodEnd) <= new Date()}
              >
                Request Cancel Subscription
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MySubscriptions;
