import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AuctionItem.css';
import { format } from 'date-fns';
import Utility from './../../utils/Utility';

const AuctionItem = ({ auction, auctionType }) => {
    const navigate = useNavigate();
    const formattedDate = format(new Date(auction.publishedDate), 'dd MMM yyyy');

    const handleNavigation = () => {
        if (auctionType === 'DirectAwards') {
            navigate(`/achizitii-directe/${auction.auctionId}`);
        } else if (auctionType === 'ProcurementProcedures') {
            navigate(`/licitatii/${auction.auctionId}`);
        }
    };

    return (
        <div className="auction-item" onClick={handleNavigation}>
            <div className="auction-header">
                <h2 className="auction-id">#{auction.auctionId}</h2>
            </div>

            <div className="auction-details">
                <h3 className="auction-title">{auction.auctionTitle}</h3>
                <p className="auction-authority">
                    {auction.city && auction.city !== 'Undefined'
                        ? auction.city
                        : 'Locație necunoscută'}
                    , {auction.authorityName}
                </p>
            </div>

            <div className="auction-footer">
                <p className="auction-value">
                    <span>Valoare estimată:</span>{' '}
                    {Utility.ConvertEstimatedValue(auction.estimatedValue)}{' '}
                    {auction.currency}
                </p>
                <p className="auction-date italic">
                    <span>Publicată la:</span> {formattedDate}
                </p>
            </div>
        </div>
    );
};

export default AuctionItem;
