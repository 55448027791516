const filterLabels = {
  auctionId: { label: 'ID Licitație', placeholder: 'Ex: 123456' },
  auctionTitle: {
    label: 'Titlu Licitație',
    placeholder: 'Ex: Construcție școală',
  },
  publishedDate: { label: 'Data Publicării', placeholder: 'Alege data' },
  cpvCode: { label: 'Cod CPV', placeholder: 'Ex: 45210000' },
  authorityName: {
    label: 'Autoritate Contractantă',
    placeholder: 'Ex: Primăria București',
  },
  authorityCUI: { label: 'CUI Autoritate', placeholder: 'Ex: 12345678' },
  city: { label: 'Oraș', placeholder: 'Ex: București' },
  county: { label: 'Județ', placeholder: 'Alege un județ' },
  minEstimatedValue: { label: 'Valoare Minimă', placeholder: 'Ex: 10000' },
  maxEstimatedValue: { label: 'Valoare Maximă', placeholder: 'Ex: 500000' },
  currency: { label: 'Monedă', placeholder: 'Ex: RON, EUR' },
};

export default filterLabels;
