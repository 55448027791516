const directAwardCounties = [
  { text: 'Alba', value: 'Alba' },
  { text: 'Arad', value: 'Arad' },
  { text: 'Argeș', value: 'Arges' },
  { text: 'Bacău', value: 'Bacau' },
  { text: 'Bihor', value: 'Bihor' },
  { text: 'Bistrița-Năsăud', value: 'Bistrita-Nasaud' },
  { text: 'Brăila', value: 'Braila' },
  { text: 'Brașov', value: 'Brasov' },
  { text: 'Botoșani', value: 'Botosani' },
  { text: 'Buzău', value: 'Buzau' },
  { text: 'Călărași', value: 'Calarasi' },
  { text: 'Caraș-Severin', value: 'Caras-Severin' },
  { text: 'Cluj', value: 'Cluj' },
  { text: 'Constanța', value: 'Constanta' },
  { text: 'Covasna', value: 'Covasna' },
  { text: 'Dâmbovița', value: 'Dambovita' },
  { text: 'Dolj', value: 'Dolj' },
  { text: 'Galați', value: 'Galati' },
  { text: 'Giurgiu', value: 'Giurgiu' },
  { text: 'Gorj', value: 'Gorj' },
  { text: 'Harghita', value: 'Harghita' },
  { text: 'Hunedoara', value: 'Hunedoara' },
  { text: 'Ialomița', value: 'Ialomita' },
  { text: 'Iași', value: 'Iasi' },
  { text: 'Ilfov', value: 'Ilfov' },
  { text: 'Maramureș', value: 'Maramures' },
  { text: 'Mehedinți', value: 'Mehedinti' },
  { text: 'Mureș', value: 'Mures' },
  { text: 'Neamț', value: 'Neamt' },
  { text: 'Olt', value: 'Olt' },
  { text: 'Prahova', value: 'Prahova' },
  { text: 'Sălaj', value: 'Salaj' },
  { text: 'Satu Mare', value: 'Satu Mare' },
  { text: 'Sibiu', value: 'Sibiu' },
  { text: 'Suceava', value: 'Suceava' },
  { text: 'Teleorman', value: 'Teleorman' },
  { text: 'Timiș', value: 'Timis' },
  { text: 'Tulcea', value: 'Tulcea' },
  { text: 'Vaslui', value: 'Vaslui' },
  { text: 'Vâlcea', value: 'Valcea' },
  { text: 'Vrancea', value: 'Vrancea' },
  { text: 'București', value: 'Bucuresti' },
];

export default directAwardCounties;
