import React from 'react';

const ChangePassword = ({ passwordEdit, setPasswordEdit, handlePasswordSave, }) => {
    return (
        <div>
            <div className="p-6 grid bg-white shadow-md rounded-lg col-span-1">
                <h2 className="text-2xl font-bold border-b-2 pb-2 mb-4">
                    Schimbă Parola
                </h2>
                {passwordEdit ? (
                    <form onSubmit={handlePasswordSave}>
                        <div className="mb-4">
                            <label className="block mb-2">Parola Actuală</label>
                            <input
                                type="password"
                                name="currentPassword"
                                className="border p-2 w-full"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2">Parola Nouă</label>
                            <input
                                type="password"
                                name="newPassword"
                                className="border p-2 w-full"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2">Confirmă Parola Nouă</label>
                            <input
                                type="password"
                                name="confirmNewPassword"
                                className="border p-2 w-full"
                                required
                            />
                        </div>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        >
                            Salvează
                        </button>
                        <button
                            type="button"
                            onClick={() => setPasswordEdit(false)}
                            className="bg-red-500 text-white px-4 py-2 ml-4 rounded hover:bg-red-600"
                        >
                            Anulează
                        </button>
                    </form>
                ) : (
                    <button
                        onClick={() => setPasswordEdit(true)}
                        className="bg-gray-500 text-white px-4 py-2 mt-4 rounded hover:bg-gray-600"
                    >
                        Modifică Parola
                    </button>
                )}
            </div>
        </div>
    );
};

export default ChangePassword;