import React from 'react';

const CompanyDetails = ({ userData, setUserData, companyEdit, setCompanyEdit, handleCompanySave }) => {
    return (
        <div>
            <div className="p-6 grid bg-white shadow-md rounded-lg col-span-1">
                <h2 className="text-2xl font-bold border-b-2 pb-2 mb-4">
                    Date Companie
                </h2>
                {companyEdit ? (
                    <div>
                        <div className="mb-4">
                            <label className="block mb-2">Nume Companie</label>
                            <input
                                type="text"
                                value={userData?.company || ''}
                                onChange={(e) =>
                                    setUserData((prev) => ({
                                        ...prev,
                                        company: e.target.value,
                                    }))
                                }
                                className="border p-2 w-full"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2">Email</label>
                            <input
                                type="text"
                                value={userData?.email || ''}
                                onChange={(e) =>
                                    setUserData((prev) => ({
                                        ...prev,
                                        email: e.target.value,
                                    }))
                                }
                                className="border p-2 w-full"
                            />
                        </div>
                        <button
                            onClick={handleCompanySave}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        >
                            Salvează
                        </button>
                        <button
                            onClick={() => setCompanyEdit(false)}
                            className="bg-red-500 text-white px-4 py-2 ml-4 rounded hover:bg-red-600"
                        >
                            Anulează
                        </button>
                    </div>
                ) : (
                    <div>
                        <p>
                            <strong>Nume Companie:</strong> {userData?.company || 'N/A'}
                        </p>
                        <p>
                            <strong>Email:</strong> {userData?.email || 'N/A'}
                        </p>
                        <p>
                            <strong>Telefon:</strong> {userData?.phone || 'N/A'}
                        </p>
                        <button
                            onClick={() => setCompanyEdit(true)}
                            className="bg-gray-500 text-white px-4 py-2 mt-4 rounded hover:bg-gray-600"
                        >
                            Modifică
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CompanyDetails;