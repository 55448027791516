import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../../services/accountService';
import './Register.css';
import Breadcrumbs from '../../components/Breadcrumbs';
import SeoPageComponent from '../../components/SeoPageComponent/SeoPageComponent';
import logo from '../../assets/icon.png';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [vatCode, setvatCode] = useState('');
  const [registrationnumber, setregistrationnumber] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Parolele nu se potrivesc.');
      return;
    }

    try {
      const { success, error } = await registerUser({
        email,
        password,
        name,
        company,
        vatCode,
        registrationnumber,
        phone,
      });

      if (success) {
        setSuccess('Cont creat cu succes!');
        setError('');
        setTimeout(() => navigate('/login'), 2000); // Redirecționare după 2 secunde
      } else {
        setError(error || 'Eroare la crearea contului.');
        setSuccess('');
      }
    } catch (err) {
      setError(err.message || 'O eroare neașteptată a apărut.');
      setSuccess('');
    }
  };

  const breadcrumbLinks = [{ href: '/', label: 'Home' }, { label: 'Register' }];

  const pageTitle = `Înregistrare - ${process.env.REACT_APP_NAME} | Portal Achiziții Publice`;
  const pageDescription = `Creează un cont pe ${process.env.REACT_APP_NAME} pentru a accesa cele mai recente licitații publice și a beneficia de toate funcționalitățile platformei dedicate achizițiilor publice.`;
  const pageKeywords = `înregistrare, creare cont, achiziții publice, licitații publice, Go4Projects, acces platformă, creare utilizator`;
  const pageUrl = `https://${process.env.REACT_APP_DOMAIN}/register`;

  return (
    <>
      <SeoPageComponent
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageKeywords={pageKeywords}
        pageUrl={pageUrl}
        logoUrl={logo}
      />

      <div className="register-section">
        <div className="register-container">
          <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
          <h2 className="register-title">Creează un cont nou</h2>
          <p className="register-subtitle">
            Completează informațiile de mai jos
          </p>
          {error && <p className="register-error">{error}</p>}
          {success && <p className="register-success">{success}</p>}
          <form onSubmit={handleSubmit} className="register-form">
            <div className="input-group">
              <label className="register-label" htmlFor="name">
                Nume complet
              </label>
              <input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Introdu numele complet"
                className="register-input"
                required
              />
            </div>
            <div className="input-group">
              <label className="register-label" htmlFor="email">
                Adresă de email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Introdu adresa de email"
                className="register-input"
                required
              />
            </div>
            <div className="input-group">
              <label className="register-label" htmlFor="company">
                Companie
              </label>
              <input
                id="company"
                type="text"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                placeholder="Introdu numele companiei"
                className="register-input"
                required
              />
            </div>

            <div className="input-group">
              <label className="register-label" htmlFor="vatcode">
                Cod Inregistrare
              </label>
              <input
                id="vatcode"
                type="text"
                value={vatCode}
                onChange={(e) => setvatCode(e.target.value)}
                placeholder="Ex. 33581368:"
                className="register-input"
                required
              />
            </div>

            <div className="input-group">
              <label className="register-label" htmlFor="registrationnumber">
                Nr Inregistrare la Registrul Comertului
              </label>
              <input
                id="registrationnumber"
                type="text"
                value={registrationnumber}
                onChange={(e) => setregistrationnumber(e.target.value)}
                placeholder="Ex: J40/244323/2025"
                className="register-input"
                required
              />
            </div>

            <div className="input-group">
              <label className="register-label" htmlFor="phone">
                Telefon
              </label>
              <input
                id="phone"
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Introdu numărul de telefon"
                className="register-input"
                required
              />
            </div>
            <div className="input-group">
              <label className="register-label" htmlFor="password">
                Parolă
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Introdu parola"
                className="register-input"
                required
              />
            </div>
            <div className="input-group">
              <label className="register-label" htmlFor="confirm-password">
                Confirmă Parola
              </label>
              <input
                id="confirm-password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirmă parola"
                className="register-input"
                required
              />
            </div>
            <button type="submit" className="register-button">
              Creează cont
            </button>
          </form>
          <div className="divider">
            <span className="line"></span>
            <span className="divider-text">sau</span>
            <span className="line"></span>
          </div>
          <div className="login-account">
            <p className="login-account-text">
              Ai deja un cont?{' '}
              <a href="/login" className="login-account-link">
                Autentifică-te
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
