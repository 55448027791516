import React, { useState } from 'react';
import SeoPageComponent from '../../components/SeoPageComponent/SeoPageComponent';
import './Home.css';
import logo from '../../assets/icon.png';
import knowledget from '../../assets/knowledge.png';
import RomaniaMap from '../../components/RomaniaMap/RomaniaMap';
import { Link } from 'react-router-dom';
import {
  FaChevronDown,
  FaChevronUp,
  FaUsers,
  FaProjectDiagram,
  FaHandshake,
  FaDollarSign,
} from 'react-icons/fa';

const Home = () => {
  const pageTitle = `Acasă - ${process.env.REACT_APP_NAME} | Portal Achiziții Publice Construcții`;
  const pageDescription = `${process.env.REACT_APP_NAME} este primul portal dedicat achizițiilor publice în domeniul construcțiilor, oferind acces ușor la licitatii, proceduri, proiecte, rapoarte AI si grupari in proiecte.`;
  const pageKeywords =
    'achiziții publice, construcții, licitații, Go4Projects, proiecte construcții, portal achiziții';
  const pageUrl = `${process.env.REACT_APP_DOMAIN}`;

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: pageTitle,
    description: pageDescription,
    url: pageUrl,
    image: `${process.env.REACT_APP_BASE_URL}/assets/icon.png`,
    publisher: {
      '@type': 'Organization',
      name: 'Go4Projects',
      logo: {
        '@type': 'ImageObject',
        url: `${process.env.REACT_APP_BASE_URL}/assets/icon.png`,
      },
    },
  };

  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: 'What is Go4Projects?',
      answer:
        'Go4Projects is a platform dedicated to enhancing the public procurement and construction industries. We connect contractors and public entities to streamline project management, bidding, and execution.',
    },
    {
      question: 'How can I join Go4Projects?',
      answer:
        'You can sign up easily by creating an account on our platform. Once registered, you can browse available projects, submit bids, and access relevant tools.',
    },
    {
      question: 'How do I submit a bid?',
      answer:
        'After signing up and logging in, navigate to the available projects. Select a project that suits your expertise and submit your bid through our user-friendly dashboard.',
    },
    {
      question: 'Is my data secure on Go4Projects?',
      answer:
        'Yes, Go4Projects ensures top-level security for all your data using advanced encryption methods and secure infrastructure.',
    },
  ];

  const toggleOpen = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const stats = [
    {
      title: 'Total Users',
      value: '5,230',
      icon: <FaUsers className="text-white text-3xl" />,
      bgColor: 'bg-blue-500',
    },
    {
      title: 'Projects Available',
      value: '1,480',
      icon: <FaProjectDiagram className="text-white text-3xl" />,
      bgColor: 'bg-green-500',
    },
    {
      title: 'Completed Projects',
      value: '3,120',
      icon: <FaHandshake className="text-white text-3xl" />,
      bgColor: 'bg-yellow-500',
    },
    {
      title: 'Total Revenue',
      value: '$2.5M',
      icon: <FaDollarSign className="text-white text-3xl" />,
      bgColor: 'bg-purple-500',
    },
  ];

  return (
    <>
      <SeoPageComponent
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageKeywords={pageKeywords}
        pageUrl={pageUrl}
        logoUrl={logo}
      />

      {/* Schema.org JSON-LD */}
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>

      {/* Secțiunea Principală */}
      <section className="py-20 bg-slate-100 -z-10">
        <div className="container max-w-7xl mx-auto px-6 md:px-12">
          <div className="grid sm:grid-cols-12 items-center gap-10">
            {/* Logo pentru mobil */}
            <div className="sm:hidden flex justify-center">
              <img src={logo} alt="Logo-ul Go4Projects" className="w-60" />
            </div>

            {/* Conținut principal */}
            <div className="sm:col-span-7 text-center sm:text-left">
              <h1 className="text-2xl lg:text-4xl xl:text-6xl font-bold leading-normal xl:leading-snug text-gray-700">
                <span className="text-indigo-500">
                  {process.env.REACT_APP_NAME}
                </span>{' '}
                - Portal Achiziții Publice Construcții
              </h1>
              <p className="text-lg lg:text-2xl text-gray-500 mt-8 mb-10 lg:mb-16 xl:mb-20">
                Simplificăm procesul achizițiilor publice în construcții,
                oferind acces transparent la date și informații detaliate.
              </p>

              <Link
                to="/licitatii"
                className="text-white bg-indigo-500 hover:bg-indigo-600 border border-indigo-500 focus:ring-4 focus:ring-indigo-300 font-medium rounded text-lg px-6 py-2 shadow-lg shadow-indigo-400 hover:shadow-white duration-300"
              >
                Vezi Licitatii
              </Link>
              <Link
                to="/achizitii-directe"
                className="text-white bg-indigo-500 hover:bg-indigo-600 border border-indigo-500 focus:ring-4 focus:ring-indigo-300 font-medium rounded text-lg px-6 py-2 shadow-lg shadow-indigo-400 hover:shadow-white duration-300"
              >
                Vezi Achizitii Directe
              </Link>
            </div>

            {/* Logo pentru desktop */}
            <div className="sm:col-span-5 sm:flex justify-end hidden">
              <img
                src={logo}
                alt="Logo-ul Go4Projects"
                className="w-full max-w-md"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Secțiunea 1: Licitații Zilnice */}
      <section className="bg-white py-20">
        <div className="container">
          {/* section title */}
          <div className="max-w-3xl mx-auto">
            <h2 className="text-center capitalize text-3xl md:text-5xl font-bold text-gray-700 mb-4 w-fit mx-auto relative after:absolute after:w-4/5 after:-bottom-3 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:border-b-4 after:border-indigo-500 after:border-double">
              Licitații zilnice actualizate
            </h2>
            <p className="text-center text-gray-500 mb-6">
              Obțineți cele mai recente informații despre licitații publice
              pentru construcții din întreaga Românie. Platforma noastră
              actualizează zilnic datele pentru a vă ține la curent cu cele mai
              noi oportunități.
            </p>
          </div>
          <RomaniaMap />
        </div>
      </section>

      {/* Secțiunea 2: Despre Platformă */}
      <section className="bg-slate-100 py-20">
        <div className="container">
          {/* section title */}
          <div className="max-w-3xl mx-auto">
            <h2 className="text-center capitalize text-3xl md:text-5xl font-bold text-gray-700 mb-5 w-fit mx-auto relative after:absolute after:w-4/5 after:-bottom-4 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:border-b-4 after:border-indigo-500 after:border-double">
              Despre {process.env.REACT_APP_NAME}
            </h2>
            <p className="text-center text-gray-500 mb-6">
              {process.env.REACT_APP_NAME} este primul portal dedicat
              achizițiilor publice pentru proiectele de construcții din România.
              Platforma noastră grupează procedurile publice în proiecte
              integrate, oferind transparență completă pentru toți utilizatorii
              implicați.
            </p>
          </div>

          <p className="text-gray-700 text-center max-w-2xl mx-auto mb-8 mt-10">
            De la planificarea achizițiilor publice până la analiza detaliată a
            procedurilor, {process.env.REACT_APP_NAME} oferă toate instrumentele
            de care aveți nevoie pentru a lua decizii informate.
          </p>

          <div className="flex flex-wrap items-center justify-center gap-5">
            <div className="shadow-lg bg-white p-4 rounded-md w-fit flex gap-4">
              <span className="shrink-0 w-16 h-16 border border-dashed border-indigo-500 text-2xl flex items-center justify-center rounded-full">
                📈
              </span>
              <div>
                <h4 className="text-2xl font-medium text-gray-800 drop-shadow-lg mb-4">
                  Performance Analytics
                </h4>
                <p className="text-gray-500 max-w-64">
                  Obțineți insight-uri detaliate despre performanța proiectelor
                  și analizați indicatorii esențiali pentru a lua decizii
                  informate.
                </p>
              </div>
            </div>
            <div className="shadow-lg bg-white p-4 rounded-md flex gap-4">
              <span className="shrink-0 w-16 h-16 border border-dashed border-indigo-500 text-2xl flex items-center justify-center rounded-full">
                💼
              </span>
              <div>
                <h4 className="text-2xl font-medium text-gray-800 drop-shadow-lg mb-4">
                  Bid Management
                </h4>
                <p className="text-gray-500 max-w-64">
                  Gestionați eficient licitațiile cu instrumente integrate ce
                  simplifică evaluarea ofertelor și cresc șansele de succes.
                </p>
              </div>
            </div>
            <div className="shadow-lg bg-white p-4 rounded-md flex gap-4">
              <span className="shrink-0 w-16 h-16 border border-dashed border-indigo-500 text-2xl flex items-center justify-center rounded-full">
                ⚙️
              </span>
              <div>
                <h4 className="text-2xl font-medium text-gray-800 drop-shadow-lg mb-4">
                  Project Collaboration
                </h4>
                <p className="text-gray-500 max-w-64">
                  Colaborați în timp real cu echipa, partajați documente și
                  asigurați o coordonare perfectă pentru proiecte de top.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Secțiunea 3: Explorare Platformă */}
      <section className="bg-white py-20">
        <div className="container text-center">
          <h2 className="text-4xl font-bold text-gray-800 mb-6">
            Explorați platforma noastră
          </h2>
          <p className="text-gray-600 max-w-3xl mx-auto mb-6">
            Platforma {process.env.REACT_APP_NAME} vă permite să explorați
            proiecte de construcții, licitații și rapoarte personalizate.
            Accesați secțiunile noastre pentru mai multe detalii.
          </p>
          <div className="flex justify-center space-x-4">
            <Link
              to="/"
              className="text-white bg-indigo-500 hover:bg-indigo-600 border border-indigo-500 focus:ring-4 focus:ring-indigo-300 font-medium rounded text-lg px-4 lg:px-6 py-2 lg:py-2 sm:mr-2 lg:mr-0 dark:bg-indigo-500 dark:hover:bg-indigo-700 focus:outline-none dark:focus:ring-indigo-800 shadow-lg shadow-indigo-400 hover:shadow-white duration-300"
            >
              Proceduri de Achiziție
            </Link>
            <Link
              to="/"
              className="text-white bg-indigo-500 hover:bg-indigo-600 border border-indigo-500 focus:ring-4 focus:ring-indigo-300 font-medium rounded text-lg px-4 lg:px-6 py-2 lg:py-2 sm:mr-2 lg:mr-0 dark:bg-indigo-500 dark:hover:bg-indigo-700 focus:outline-none dark:focus:ring-indigo-800 shadow-lg shadow-indigo-400 hover:shadow-white duration-300"
            >
              Proiecte
            </Link>
            <Link
              to="/"
              className="text-white bg-indigo-500 hover:bg-indigo-600 border border-indigo-500 focus:ring-4 focus:ring-indigo-300 font-medium rounded text-lg px-4 lg:px-6 py-2 lg:py-2 sm:mr-2 lg:mr-0 dark:bg-indigo-500 dark:hover:bg-indigo-700 focus:outline-none dark:focus:ring-indigo-800 shadow-lg shadow-indigo-400 hover:shadow-white duration-300"
            >
              Rapoarte
            </Link>
          </div>
        </div>
      </section>

      {/* Secțiunea 4: Cunoaștere Aprofundată */}
      <section className="bg-slate-100 py-24">
        <div className="container max-w-7xl mx-auto px-6 md:px-12 lg:px-24">
          <div className="grid grid-cols-1 lg:grid-cols-12 items-center gap-10">
            {/* Text */}
            <div className="lg:col-span-7 text-center lg:text-left">
              <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold lg:leading-snug drop-shadow-lg text-gray-800 mb-10">
                Cunoaștere aprofundată a pieței publice de construcții
              </h2>
              <p className="text-gray-600 md:text-xl md:leading-9 mb-5">
                Platforma noastră vă oferă acces la informații de piață
                actualizate, fiabile și bazate pe expertiză. Cu{' '}
                <span className="text-indigo-500 font-semibold">
                  {process.env.REACT_APP_NAME}
                </span>
                , aveți la dispoziție date și analize detaliate pentru a vă
                îmbunătăți performanța și deciziile strategice.
              </p>
              <p className="text-gray-600 md:text-xl md:leading-9">
                Experimentați astăzi avantajele platformei noastre și
                alăturați-vă utilizatorilor care beneficiază deja de un avantaj
                competitiv semnificativ!
              </p>
            </div>

            {/* Imagine */}
            <div className="lg:col-span-5 flex justify-center lg:justify-end">
              <img
                src={knowledget}
                alt="knowledget"
                className="max-w-full h-auto"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Platform Statistics */}
      <section className="bg-white py-20">
        <div className="container">
          {/* section title */}
          <div className="max-w-3xl mx-auto">
            <h2 className="text-center capitalize text-3xl md:text-5xl font-bold text-gray-700 mb-4 w-fit mx-auto relative after:absolute after:w-4/5 after:-bottom-4 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:border-b-4 after:border-indigo-500 after:border-double">
              Platform Statistics
            </h2>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-10">
            {stats.map((stat, index) => (
              <div
                key={index}
                className="stat-card bg-white p-6 rounded-lg shadow-lg flex items-center justify-center"
              >
                <div className={`stat-icon p-4 rounded-full ${stat.bgColor}`}>
                  {stat.icon}
                </div>
                <div className="stat-info ml-4">
                  <h3 className="text-lg font-semibold">{stat.title}</h3>
                  <p className="text-2xl font-bold">{stat.value}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Frequently Asked Questions */}
      <section className="bg-slate-100 py-20">
        <div className="container">
          {/* section title */}
          <div className="max-w-3xl mx-auto">
            <h2 className="text-center capitalize text-3xl md:text-5xl font-bold text-gray-700 mb-4 w-fit mx-auto relative after:absolute after:w-4/5 after:-bottom-4 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:border-b-4 after:border-indigo-500 after:border-double">
              Întrebări Frecvente
            </h2>
            <p className="text-center text-gray-500 mb-6">
              Ai întrebări? Găsește răspunsurile aici! Descoperă informații
              esențiale despre platforma noastră și modul în care te poate
              ajuta.
            </p>
          </div>

          <div className="mt-10 max-w-6xl mx-auto">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="mb-4 bg-white rounded-lg border border-gray-300"
              >
                <button
                  onClick={() => toggleOpen(index)}
                  className="w-full text-left text-lg font-medium p-4 hover:bg-indigo-200 outline-none duration-300"
                >
                  <div className="flex justify-between items-center">
                    <span>{faq.question}</span>
                    {openIndex === index ? (
                      <FaChevronUp className="text-gray-500" />
                    ) : (
                      <FaChevronDown className="text-gray-500" />
                    )}
                  </div>
                </button>
                <div
                  className={`${
                    openIndex === index ? 'max-h-screen' : 'max-h-0'
                  } overflow-hidden transition-all duration-300 ease-in-out`}
                >
                  <p className="p-4 text-gray-700">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
