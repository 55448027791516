// ../../constants/contactPoint.js
const contactPoint = {
  displayEmail: 'info [at] go4projects [dot] com',
  phone: '+40 722 405 744',
  companyName: 'BIMART SOLUTIONS SRL',
  addressLine1: 'Calea Victoriei 155, București, 010073',
  address: 'Calea Victoriei,nr. 155, bl. D1, Sector 1, București',
  taxIdentificationNumber: 'RO 33581368',
  registrationNumber: 'J40/705/2016',
};

export default contactPoint;
