import React, { useEffect, useState } from 'react';
import {
  getPlans,
  checkoutSubscription,
} from '../../services/subscriptionService';
import SeoPageComponent from '../../components/SeoPageComponent/SeoPageComponent';
import Breadcrumbs from '../../components/Breadcrumbs';
import Cookies from 'js-cookie';
import Utility from './../../utils/Utility';

const Subscriptions = ({ setIsAuthenticated }) => {
  const [plans, setPlans] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState({}); // State pentru butonul cu spinner

  const isAuth = Utility.getUserAuth();

  useEffect(() => {
    const fetchPlans = async () => {
      const token = Cookies.get('jwtToken');
      try {
        if (token) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
        const response = await getPlans();
        setPlans(response || []);
      } catch (err) {
        setError('Failed to load plans. Please try again.');
        console.error(err);
      }
    };
    fetchPlans();
  }, [setIsAuthenticated]);

  const handleSubscribe = async (planId) => {
    setLoading((prev) => ({ ...prev, [planId]: true })); // Setează spinner activ
    const userId = Cookies.get('userId');

    try {
      const response = await checkoutSubscription(userId, planId);
      window.location.replace(response.url); // Redirecționează către Stripe
    } catch (err) {
      setError('Subscription failed. Please try again.');
      console.error(err);
    } finally {
      setLoading((prev) => ({ ...prev, [planId]: false })); // Dezactivează spinnerul
    }
  };

  return (
    <>
      <SeoPageComponent
        pageTitle="Abonamente - Go4Projects | Planuri pentru Acces la Licitații Publice"
        pageDescription="Alege unul dintre abonamentele noastre pentru a avea acces complet la licitațiile și proiectele de construcții publice pe Go4Projects."
        pageKeywords="abonamente licitatii, acces licitații, abonament Go4Projects, achiziții publice"
        pageUrl="https://go4projects.com/abonamente"
      />
      <section className="bg-gray-50 py-16">
        <Breadcrumbs
          breadcrumbLinks={[
            { href: '/', label: 'Home' },
            { label: 'Abonamente' },
          ]}
        />
        <div className="max-w-screen-xl mx-auto px-6 lg:px-8">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-extrabold text-gray-800">
              Planuri Flexibile pentru Nevoile Tale
            </h1>
            <p className="mt-4 text-lg text-gray-600">
              Explorează planurile noastre și descoperă soluția perfectă.
            </p>
          </div>
          {error && (
            <div className="text-red-500 text-center mb-4">{error}</div>
          )}
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {plans.length > 0 ? (
              plans.map((plan) => (
                <div
                  key={plan.id}
                  className="flex flex-col justify-between p-6 bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
                >
                  <div>
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                      {plan.name}
                    </h2>
                    <p className="text-gray-600 mb-6">{plan.description}</p>
                    <div className="flex items-baseline justify-center mb-6">
                      <span className="text-4xl font-extrabold text-gray-900">
                        {plan.price}
                      </span>
                      <span className="text-2xl font-bold text-gray-900">
                        {plan.currency}
                      </span>
                      <span className="ml-2 text-gray-600">
                        {plan.interval === 'year'
                          ? '/an'
                          : plan.interval === 'month'
                            ? '/luna'
                            : plan.interval === 'week'
                              ? '/saptamana'
                              : plan.interval === 'day'
                                ? '/zi'
                                : ' .. '}
                      </span>
                    </div>
                    <ul className="space-y-3">
                      {plan.features &&
                        plan.features.map((feature, idx) => (
                          <li key={idx} className="flex items-center">
                            <svg
                              className="w-5 h-5 text-green-500 mr-3"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span>{feature}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                  {isAuth && (
                    <div className="mt-6">
                      <button
                        onClick={() => handleSubscribe(plan.id)}
                        disabled={loading[plan.id]}
                        className="block w-full text-center bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition flex justify-center items-center"
                      >
                        {loading[plan.id] ? (
                          <svg
                            className="animate-spin h-5 w-5 mr-2 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                            ></path>
                          </svg>
                        ) : (
                          'Abonează-te'
                        )}
                      </button>
                    </div>
                  )}
                  {!isAuth && (
                    <div className="mt-6 p-6 border border-gray-300 rounded-lg shadow-md bg-gray-50 text-center">
                      <p className="text-gray-800 text-lg">
                        Pentru a vă abona la{' '}
                        <span className="font-bold">{plan.Name}</span>, trebuie
                        să vă creați cont sau să vă autentificați.
                      </p>
                      <a
                        href="/login"
                        className="mt-4 inline-block bg-blue-600 text-white py-2 px-6 rounded-lg hover:bg-blue-700 transition"
                      >
                        Login
                      </a>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p className="text-center text-gray-600">
                Momentan nu sunt disponibile abonamente.
              </p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Subscriptions;
