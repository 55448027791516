import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ breadcrumbLinks }) => {
    return (
        <div className='border-b-2 border-dashed'>
            <div className='container'>
                <nav className="py-4 bg-white text-gray-600">
                    <ol className="flex items-center space-x-2">
                        {breadcrumbLinks.map((link, index) => (
                            <React.Fragment key={index}>
                                {link.href ? (
                                    <li>
                                        <Link to={link.href} className="text-indigo-600 hover:underline">
                                            {link.label}
                                        </Link>
                                    </li>
                                ) : (
                                    <li>
                                        <span className="text-gray-800">{link.label}</span>
                                    </li>
                                )}
                                {index < breadcrumbLinks.length - 1 && <li>/</li>}
                            </React.Fragment>
                        ))}
                    </ol>
                </nav>
            </div>
        </div>
    );
};

export default Breadcrumbs;
