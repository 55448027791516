import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../services/accountService';
import './Login.css';
import logo from '../../assets/icon.png';
import Breadcrumbs from '../../components/Breadcrumbs';
import SeoPageComponent from '../../components/SeoPageComponent/SeoPageComponent';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { success, error } = await loginUser({ email, password }, navigate);

      if (success) {
        navigate('/account');
      } else {
        setError(error || 'Invalid credentials.');
      }
    } catch (err) {
      setError(err.message || 'An unexpected error occurred.');
    }
  };

  const breadcrumbLinks = [{ href: '/', label: 'Home' }, { label: 'Login' }];

  const pageTitle = `Autentificare - ${process.env.REACT_APP_NAME} | Portal Achiziții Publice`;
  const pageDescription = `Autentifică-te pe ${process.env.REACT_APP_NAME} pentru a accesa cele mai recente licitații publice și resurse dedicate achizițiilor publice din România.`;
  const pageKeywords = `autentificare, login, achiziții publice, licitații publice, Go4Projects, acces platformă, autentificare utilizator`;
  const pageUrl = `https://${process.env.REACT_APP_DOMAIN}/login`;

  return (
    <>
      <SeoPageComponent
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageKeywords={pageKeywords}
        pageUrl={pageUrl}
        logoUrl={logo}
      />
      <div className="login-container">
        <div className="login-card">
          <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
          <h2 className="login-title">Bine ai venit!</h2>
          <p className="login-subtitle">Autentifică-te pentru a continua</p>
          {error && <p className="login-error">{error}</p>}
          <form onSubmit={handleSubmit} className="login-form">
            <div className="input-group">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Introdu adresa de email"
                className="login-input"
                required
              />
            </div>
            <div className="input-group">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Introdu parola"
                className="login-input"
                required
              />
            </div>
            <div className="forgot-password">
              <a href="/forgot-password" className="forgot-password-link">
                Am uitat parola?
              </a>
            </div>
            <button type="submit" className="login-button">
              Autentificare
            </button>
          </form>
          <div className="divider">
            <span className="line"></span>
            <span className="divider-text">sau</span>
            <span className="line"></span>
          </div>
          <div className="create-account">
            <p className="create-account-text">
              Nu ai un cont?{' '}
              <a href="/register" className="create-account-link">
                Creează cont nou
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
