import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Loader2, CheckCircle, XCircle } from 'lucide-react';
import { activateSubscription } from '../../services/subscriptionService';

const Success = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const sessionId = searchParams.get('sessionId');
  const subscriptionId = searchParams.get('subscriptionId');

  const [status, setStatus] = useState('loading'); // 'loading', 'success', 'error'
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionId || !subscriptionId) {
      setStatus('error');
      setMessage('ID-ul sesiunii sau ID-ul abonamentului lipsește.');
      return;
    }

    const processSubscription = async () => {
      const userId = Cookies.get('userId');

      const localSessionId = localStorage.getItem('sessionId');

      if (!localSessionId) {
        Cookies.set('sessionId', sessionId);
        try {
          const response = await activateSubscription(
            userId,
            sessionId,
            subscriptionId,
          );

          // Stochează noile informații despre utilizator
          Cookies.set('jwtToken', response.token);
          Cookies.set('userId', response.userId);
          Cookies.set('subscriptionType', response.subscriptionType);

          setStatus('success');
          setMessage('Abonamentul a fost activat cu succes!');
        } catch (err) {
          setStatus('error');
          setMessage('Eroare la activarea abonamentului. Încearcă din nou.');
          console.error(err);
        }
      }
    };

    processSubscription();
  }, [sessionId, subscriptionId]); // 🔹 Adăugăm dependențele

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 px-4">
      <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-md text-center">
        {status === 'loading' && (
          <div className="flex flex-col items-center">
            <Loader2 className="animate-spin w-12 h-12 text-gray-500" />
            <h1 className="text-lg font-semibold text-gray-700 mt-4">
              Se procesează...
            </h1>
          </div>
        )}

        {status === 'success' && (
          <div className="flex flex-col items-center">
            <CheckCircle className="w-12 h-12 text-green-600" />
            <h1 className="text-xl font-bold text-green-600 mt-4">Succes!</h1>
            <p className="text-gray-600 my-3">{message}</p>
            <button
              onClick={() => navigate('/')}
              className="mt-4 px-5 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Mergi la Home
            </button>
          </div>
        )}

        {status === 'error' && (
          <div className="flex flex-col items-center">
            <XCircle className="w-12 h-12 text-red-600" />
            <h1 className="text-xl font-bold text-red-600 mt-4">Eroare</h1>
            <p className="text-gray-600 my-3">{message}</p>
            <button
              onClick={() => navigate('/')}
              className="mt-4 px-5 py-2 bg-gray-500 text-white font-semibold rounded-lg hover:bg-gray-600 transition duration-200"
            >
              Înapoi la Home
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Success;
