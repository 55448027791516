import React from 'react';
import SeoPageComponent from './SeoPageComponent/SeoPageComponent';

const NotFoundComponent = () => {
  const pageTitle = '404 - Pagină Negăsită | Go4Projects';
  const pageDescription =
    'Pagina pe care o cauți nu a fost găsită. Accesează alte secțiuni ale site-ului pentru a găsi informațiile dorite.';
  const pageUrl = `${process.env.REACT_APP_DOMAIN}/404`;

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: '404 - Pagină Negăsită',
    description: pageDescription,
    url: pageUrl,
  };

  return (
    <>
      {/* SEO */}
      <SeoPageComponent
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageUrl={pageUrl}
      />

      {/* Schema.org JSON-LD */}
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>

      {/* 404 Content */}
      <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 px-4 text-center">
        <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
        <p className="text-lg text-gray-600 mb-6">
          Ne pare rău, pagina pe care o cauți nu există sau a fost mutată.
        </p>
        <a
          href="/"
          className="text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-indigo-600 dark:hover:bg-indigo-700 focus:outline-none dark:focus:ring-indigo-800"
        >
          Întoarce-te la Pagina Principală
        </a>
        <p className="text-sm text-gray-500 mt-4">
          Dacă ai ajuns aici din greșeală, te rugăm să ne{' '}
          <a href="/contact" className="text-blue-500 underline">
            contactezi
          </a>
          .
        </p>
      </div>
    </>
  );
};

export default NotFoundComponent;
