import React from 'react';
import { Link } from 'react-router-dom';
import {
  CalendarDays,
  Building2,
  FileText,
  BadgeEuro,
  Clock3,
  ArrowRightCircle,
} from 'lucide-react';

const ProjectCard = ({ project }) => {
  const {
    id,
    projectTitle,
    authorityName,
    estimatedValue,
    durationExecution,
    startDate,
    endDate,
    extensions,
  } = project;

  return (
    <div className="bg-white border border-gray-200 rounded-2xl shadow hover:shadow-lg transition-all p-6 space-y-5">
      {/* Header: Titlu + Autoritate */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between">
        <div>
          <Link
            to={`/proiecte/${id}`}
            className="text-2xl font-bold text-indigo-700 hover:underline"
          >
            {projectTitle}
          </Link>
          <p className="mt-1 text-sm text-gray-500 flex items-center gap-2">
            <Building2 size={16} /> {authorityName}
          </p>
        </div>
        <Link
          to={`/proiecte/${id}`}
          className="mt-3 md:mt-0 inline-flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white text-sm font-semibold rounded-lg hover:bg-indigo-700 transition"
        >
          Vezi detalii <ArrowRightCircle size={18} />
        </Link>
      </div>

      {/* Info: Valoare, durată, perioadă */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 text-sm text-gray-700">
        <div className="flex items-center gap-2">
          <BadgeEuro size={20} className="text-green-600" />
          <div>
            <p className="text-gray-500 font-medium">Valoare estimată</p>
            <p className="font-bold text-gray-800">
              {estimatedValue?.toLocaleString('ro-RO')} RON
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Clock3 size={20} className="text-blue-500" />
          <div>
            <p className="text-gray-500 font-medium">Durată execuție</p>
            <p className="font-bold text-gray-800">{durationExecution} luni</p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <CalendarDays size={20} className="text-purple-500" />
          <div>
            <p className="text-gray-500 font-medium">Perioadă estimată</p>
            <p className="font-bold text-gray-800">
              {startDate} – {endDate}
            </p>
          </div>
        </div>
      </div>

      {/* Proceduri */}
      {extensions?.length > 0 && (
        <div className="pt-4 border-t">
          <p className="text-sm font-medium text-gray-600 mb-2 flex items-center gap-2">
            <FileText size={18} /> Proceduri asociate ({extensions.length})
          </p>
          <ul className="list-disc list-inside space-y-1 text-sm text-gray-700">
            {extensions.map((proc, idx) => (
              <li key={idx}>
                {proc.procedureTitle}{' '}
                <span className="text-gray-400 italic">({proc.startDate})</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProjectCard;
