const counties = [
  { text: 'Bihor', value: 'RO111 Bihor' },
  { text: 'Bistrița-Năsăud', value: 'RO112 Bistrita-Nasaud' },
  { text: 'Cluj', value: 'RO113 Cluj' },
  { text: 'Maramureș', value: 'RO114 Maramures' },
  { text: 'Satu Mare', value: 'RO115 Satu Mare' },
  { text: 'Sălaj', value: 'RO116 Salaj' },
  { text: 'Alba', value: 'RO121 Alba' },
  { text: 'Brașov', value: 'RO122 Brasov' },
  { text: 'Covasna', value: 'RO123 Covasna' },
  { text: 'Harghita', value: 'RO124 Harghita' },
  { text: 'Mureș', value: 'RO125 Mures' },
  { text: 'Sibiu', value: 'RO126 Sibiu' },
  { text: 'Bacău', value: 'RO211 Bacau' },
  { text: 'Botoșani', value: 'RO212 Botosani' },
  { text: 'Iași', value: 'RO213 Iasi' },
  { text: 'Neamț', value: 'RO214 Neamt' },
  { text: 'Suceava', value: 'RO215 Suceava' },
  { text: 'Vaslui', value: 'RO216 Vaslui' },
  { text: 'Brăila', value: 'RO221 Braila' },
  { text: 'Buzău', value: 'RO222 Buzau' },
  { text: 'Constanța', value: 'RO223 Constanta' },
  { text: 'Galați', value: 'RO224 Galati' },
  { text: 'Tulcea', value: 'RO225 Tulcea' },
  { text: 'Vrancea', value: 'RO226 Vrancea' },
  { text: 'Argeș', value: 'RO311 Arges' },
  { text: 'Călărași', value: 'RO312 Calarasi' },
  { text: 'Dâmbovița', value: 'RO313 Dambovita' },
  { text: 'Giurgiu', value: 'RO314 Giurgiu' },
  { text: 'Ialomița', value: 'RO315 Ialomita' },
  { text: 'Prahova', value: 'RO316 Prahova' },
  { text: 'Teleorman', value: 'RO317 Teleorman' },
  { text: 'București', value: 'RO321 Bucuresti' },
  { text: 'Ilfov', value: 'RO322 Ilfov' },
  { text: 'Dolj', value: 'RO411 Dolj' },
  { text: 'Gorj', value: 'RO412 Gorj' },
  { text: 'Mehedinți', value: 'RO413 Mehedinti' },
  { text: 'Olt', value: 'RO414 Olt' },
  { text: 'Vâlcea', value: 'RO415 Valcea' },
  { text: 'Arad', value: 'RO421 Arad' },
  { text: 'Caraș-Severin', value: 'RO422 Caras-Severin' },
  { text: 'Hunedoara', value: 'RO423 Hunedoara' },
  { text: 'Timiș', value: 'RO424 Timis' },
];

export default counties;
