import React, { useState, useEffect } from 'react';
import { validateToken } from '../../services/accountService';

const RestrictedAccess = ({
  children,
  compact = false,
  message = 'Acces restricționat. Te rugăm să te autentifici pentru a accesa această secțiune.',
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null -> loading, true/false -> autentificat/neautentificat

  useEffect(() => {
    const checkAuth = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Spinner vizibil pentru o secundă
      const result = await validateToken();
      setIsAuthenticated(result.valid);
    };

    checkAuth();
  }, []);

  if (isAuthenticated === null) {
    return (
      <div className="h-7 max-w-72 rounded-md bg-gray-200 animate-pulse"></div>
    );
  }

  if (!isAuthenticated) {
    return (
      <span
        className={`inline-block border ${
          compact
            ? 'border-gray-300 bg-gray-50 py-1 px-2'
            : 'border-gray-400 bg-gray-100 py-2 px-3'
        } rounded-lg text-gray-700 ${
          compact ? 'text-sm font-medium' : 'text-base font-semibold'
        }`}
      >
        {message}
      </span>
    );
  }

  return <>{children}</>;
};

export default RestrictedAccess;
