import React from 'react';
import { Helmet } from 'react-helmet-async';

const CSPConfig = () => {
  const isProduction = process.env.REACT_APP_PRODUCTION === 'true';
  const apiBaseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:7039';

  return (
    <Helmet>
      <meta
        httpEquiv="Content-Security-Policy"
        content={`
      default-src 'self';
      script-src 'self' 'unsafe-inline' 'unsafe-eval' https://www.gstatic.com https://www.google.com https://www.googleapis.com;
      style-src 'self' 'unsafe-inline';
      img-src 'self' data: blob: https://www.gstatic.com https://www.google.com;
      connect-src 'self' ${apiBaseUrl} https://www.gstatic.com https://www.google.com;
      frame-src 'self' https://www.google.com https://www.gstatic.com https://chart.googleapis.com;
      font-src 'self' data:;
      base-uri 'self';
      form-action 'self';
      object-src 'none';
      upgrade-insecure-requests;
    `}
      />
    </Helmet>
  );
};

export default CSPConfig;
