import Cookies from 'js-cookie';

class Utility {
  static ConvertEstimatedValue(value) {
    if (value == null) {
      return 'N/A';
    }
    return value.toLocaleString('ro-RO', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  // Conversie dată într-un format elegant
  static FormatDate(dateString) {
    if (!dateString) {
      return 'N.AA';
    }

    const date = new Date(dateString);
    if (isNaN(date)) {
      return 'N.AA';
    }

    const options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, // Format 24 de ore
    };

    const formattedDate = date.toLocaleString('ro-RO', options);
    return formattedDate.replace(',', ' Ora');
  }

  static FormatDateLight(dateString) {
    if (!dateString) {
      return 'N/A';
    }

    try {
      // Convertim string-ul de dată în obiect Date
      const [day, month, year] = dateString.split('.');
      const date = new Date(`${day}:${month}:${year}`);

      if (isNaN(date)) {
        return 'N.AA';
      }

      // Formatăm data folosind toLocaleDateString pentru limba română
      const options = {
        day: 'numeric',
        month: 'short', // "short" pentru lunile mai scurte (ex: "mai")
        year: 'numeric',
      };

      return date.toLocaleDateString('ro-RO', options); // Returnăm data formatată
    } catch {
      return 'N/A'; // Dacă apare vreo eroare, returnăm "N.AA"
    }
  }

  static getUserAuth() {
    const token = Cookies.get('jwtToken');

    if (!token) {
      return false;
    } else {
      return true;
    }
  }
}

export default Utility;
