import React, { useState, useEffect } from 'react';
import svg_ro from './../../assets/ro.svg';
import { parse } from 'svgson';
import './RomaniaMap.css';

// Mapare județe -> coduri NUTS3
const nutsMapping = {
  ROAB: 'RO121', // Alba
  ROAR: 'RO421', // Arad
  ROAG: 'RO311', // Argeș
  ROBC: 'RO211', // Bacău
  ROBH: 'RO111', // Bihor
  ROBN: 'RO112', // Bistrița-Năsăud
  ROBT: 'RO212', // Botoșani
  ROBR: 'RO122', // Brașov
  ROBZ: 'RO222', // Buzău
  ROCS: 'RO422', // Caraș-Severin
  ROCL: 'RO113', // Cluj
  ROCT: 'RO313', // Constanța
  ROCV: 'RO123', // Covasna
  RODB: 'RO314', // Dâmbovița
  RODJ: 'RO411', // Dolj
  ROGL: 'RO223', // Galați
  ROGR: 'RO315', // Giurgiu
  ROGJ: 'RO412', // Gorj
  ROHR: 'RO124', // Harghita
  ROHD: 'RO125', // Hunedoara
  ROIF: 'RO317', // Ilfov
  ROIS: 'RO213', // Iași
  ROMM: 'RO114', // Maramureș
  ROMH: 'RO413', // Mehedinți
  ROMS: 'RO126', // Mureș
  RONM: 'RO214', // Neamț
  ROOT: 'RO414', // Olt
  ROPH: 'RO318', // Prahova
  ROSM: 'RO115', // Satu Mare
  ROSJ: 'RO116', // Sălaj
  ROSB: 'RO127', // Sibiu
  ROSV: 'RO215', // Suceava
  ROTR: 'RO319', // Teleorman
  ROTM: 'RO423', // Timiș
  ROVS: 'RO216', // Vaslui
  ROVL: 'RO415', // Vâlcea
  ROVN: 'RO225', // Vrancea
  ROTL: 'RO224', // Tulcea (corectat)
  ROBU: 'RO321', // București
  ROCJ: 'RO316', // Călărași
  ROIL: 'RO322', // Ialomița (corectat)
};

const RomaniaMap = () => {
  const [counties, setCounties] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState(null);

  useEffect(() => {
    fetch(svg_ro)
      .then((response) => response.text())
      .then((svgText) => parse(svgText))
      .then((parsedSvg) => {
        const paths = parsedSvg.children.find((child) => child.name === 'g');
        if (paths) {
          const countyPaths = paths.children
            .filter((child) => child.name === 'path')
            .map((path) => {
              const oldId = path.attributes.id || '';
              const nutsCode = nutsMapping[oldId] || oldId; // Înlocuim cu codul NUTS dacă există
              const countyName =
                path.attributes['data-name'] ||
                path.attributes['name'] ||
                'Județ Necunoscut'; // Corecție mapare nume județ

              return {
                id: nutsCode,
                name: countyName,
                d: path.attributes.d || '',
              };
            });
          setCounties(countyPaths);
        }
      })
      .catch((error) =>
        console.error('Eroare la extragerea datelor din SVG:', error),
      );
  }, []);

  const handleCountyClick = (county) => {
    setSelectedCounty(county);
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center mt-16">
      <div className="w-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 704"
          className=""
        >
          {counties.map((county) => (
            <path
              key={county.id}
              d={county.d}
              data-name={county.name}
              onClick={() => handleCountyClick(county)}
              className={`romania-map-path ${
                selectedCounty?.id === county.id ? 'selected' : ''
              }`}
            />
          ))}
        </svg>
      </div>

      {/* Descriere și detalii - Desktop */}
      <div className="bg-white text-center lg:text-left">
        <h2 className="text-2xl md:text-4xl font-semibold text-gray-800 mb-4 xl:mb-8">
          🗺️ Licitații grupate pe Județele României
        </h2>
        <p className="text-gray-600 lg:mb-10 xl:mb-16">
          România este organizată administrativ în <strong>41 de județe</strong>{' '}
          și municipiul București. Fiecare unitate teritorială este identificată
          printr-un <strong>cod unic NUTS3</strong>, utilizat la nivel european
          pentru analiza datelor regionale și dezvoltare economică.
          <br />
          <br />
          Aceste coduri sunt folosite în alocarea fondurilor europene și în
          statisticile regionale pentru politici publice.
        </p>

        <div className="shadow-lg rounded p-5 w-fit lg:block hidden">
          <h4 className="text-xl md:text-2xl text-gray-800 font-medium">
            Detalii despre județul selectat
          </h4>

          {selectedCounty ? (
            <p className="text-gray-600 mt-3 text-lg">
              <strong>Județ:</strong> {selectedCounty.name} <br />
              <strong>Cod NUTS3:</strong>{' '}
              <span className="text-indigo-500">{selectedCounty.id}</span>
            </p>
          ) : (
            <p className="text-gray-600 mt-3 text-lg">
              Selectează un județ pentru a vedea detalii.
            </p>
          )}
        </div>
      </div>

      {/* Varianta pentru mobile - acum funcțională */}
      <div className="shadow-lg rounded p-5 w-fit mx-auto block lg:hidden">
        <h4 className="text-xl md:text-2xl text-gray-800 font-medium">
          Detalii despre județul selectat
        </h4>

        {selectedCounty ? (
          <p className="text-gray-600 mt-3 text-lg">
            <strong>Județ:</strong> {selectedCounty.name} <br />
            <strong>Cod NUTS3:</strong>{' '}
            <span className="text-indigo-500">{selectedCounty.id}</span>
          </p>
        ) : (
          <p className="text-gray-600 mt-3 text-lg">
            Selectează un județ pentru a vedea detalii.
          </p>
        )}
      </div>
    </div>
  );
};

export default RomaniaMap;
