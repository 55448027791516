import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { confirmEmail } from '../../services/accountService';

const ConfirmEmail = () => {
  const [status, setStatus] = useState(null); // `null`, `success`, or `error`
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const confirm = async () => {
      const token = searchParams.get('token');
      if (!token) {
        setStatus('error');
        setMessage('Token-ul este invalid sau lipsește.');
        return;
      }

      try {
        const { success, message } = await confirmEmail(token);
        if (success) {
          setStatus('success');
          setMessage('Email-ul a fost confirmat cu succes.');
        } else {
          setStatus('error');
          setMessage(message || 'A apărut o eroare la confirmarea email-ului.');
        }
      } catch (err) {
        setStatus('error');
        setMessage('O eroare neașteptată a apărut.');
      }
    };

    confirm();
  }, [searchParams]);

  return (
    <div className="min-h-screen flex flex-col justify-center items-center px-4 py-6 bg-gray-100">
      <div className="max-w-lg bg-white shadow-md rounded-lg p-6 text-center">
        {status === 'success' && (
          <>
            <h1 className="text-xl font-bold text-green-600">Succes!</h1>
            <p className="text-gray-600 my-4">{message}</p>
            <button
              onClick={() => navigate('/login')}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Mergi la Login
            </button>
          </>
        )}
        {status === 'error' && (
          <>
            <h1 className="text-xl font-bold text-red-600">Eroare</h1>
            <p className="text-gray-600 my-4">{message}</p>
            <button
              onClick={() => navigate('/')}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            >
              Înapoi la Home
            </button>
          </>
        )}
        {status === null && (
          <h1 className="text-xl font-bold text-gray-800">Se procesează...</h1>
        )}
      </div>
    </div>
  );
};

export default ConfirmEmail;
