import React, { useState, useEffect, useCallback } from 'react';
import SeoPageComponent from '../../components/SeoPageComponent/SeoPageComponent';
import './DirectAwards.css';
import { fetchDirectAwardSearchResults } from '../../services/publicTendersApi';
import AuctionList from '../../components/AuctionList/AuctionList';
import logo from '../../assets/icon.png';
import Breadcrumbs from '../../components/Breadcrumbs';
import ListLoadingHolder from '../../components/LoadingHolders/ListLoadingHolder';
import directAwardCounties from './directAwardCounties';
import directAwardFilterLabels from './directAwardFilterLabels';

const DirectAwards = () => {
  const [filters, setFilters] = useState({
    auctionId: '',
    auctionTitle: '',
    publishedDate: '',
    cpvCode: '',
    authorityName: '',
    authorityCUI: '',
    city: '',
    county: '',
    minEstimatedValue: '',
    maxEstimatedValue: '',
    currency: '',
    pageIndex: 0,
    pageSize: 10,
  });

  const [errors, setErrors] = useState([]);
  const [results, setResults] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false); // ✅ Starea pentru dropdown-ul filtrelor

  const validateValues = useCallback(() => {
    const validationErrors = [];
    if (parseFloat(filters.minEstimatedValue) < 0) {
      validationErrors.push('Valoarea estimată minimă nu poate fi negativă.');
    }
    if (parseFloat(filters.maxEstimatedValue) < 0) {
      validationErrors.push('Valoarea estimată maximă nu poate fi negativă.');
    }
    if (
      parseFloat(filters.maxEstimatedValue) <
      parseFloat(filters.minEstimatedValue)
    ) {
      validationErrors.push(
        'Valoarea estimată minimă nu poate fi mai mare decât valoarea maximă.',
      );
    }
    setErrors(validationErrors);
    return validationErrors.length === 0;
  }, [filters]);

  const handleFilter = useCallback(
    async (pageIndex = 0) => {
      if (validateValues()) {
        setLoading(true);
        try {
          const searchFilters = { ...filters, pageIndex };
          const data = await fetchDirectAwardSearchResults(searchFilters);
          setResults(data.results);
          setTotalResults(data.totalResults);
        } catch (error) {
          console.error('Error fetching search results:', error);
        } finally {
          setLoading(false);
        }
      }
    },
    [filters, validateValues],
  );

  useEffect(() => {
    handleFilter();
  }, [handleFilter]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFilters({
      auctionId: '',
      auctionTitle: '',
      publishedDate: '',
      cpvCode: '',
      authorityName: '',
      authorityCUI: '',
      city: '',
      county: '',
      minEstimatedValue: '',
      maxEstimatedValue: '',
      currency: '',
      pageIndex: 0,
      pageSize: 10,
    });
    setErrors([]);
    setResults([]);
    setTotalResults(0);
    handleFilter();
  };

  const breadcrumbLinks = [
    { href: '/', label: 'Home' },
    { label: 'Achizitii Directe' },
  ];

  return (
    <>
      <SeoPageComponent
        pageTitle="Achiziții Directe - Go4Projects"
        pageDescription="Descoperă cele mai recente achiziții directe pentru proiecte de construcții."
        pageUrl="https://go4projects.com/achizitii-directe"
        logoUrl={logo}
      />

      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />

      <section className="bg-white dark:bg-white-900 py-8 section">
        <div className="container">
          {/* 🔽 Dropdown pentru filtre */}
          <div className="bg-gray-100 p-4 rounded-lg shadow-md">
            <button
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              className="w-full bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-indigo-300 flex justify-between items-center"
            >
              {isFilterOpen ? 'Ascunde filtrele ▲' : 'Afișează filtrele ▼'}
            </button>

            {/* ✅ Panoul de filtre (vizibil doar dacă `isFilterOpen` este true) */}
            {isFilterOpen && (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-4">
                {Object.keys(filters).map((filter) =>
                  filter === 'county' ? (
                    <div key={filter}>
                      <label className="block text-gray-600 mb-2">
                        {directAwardFilterLabels[filter]?.label || filter}
                      </label>
                      <select
                        name={filter}
                        value={filters[filter]}
                        onChange={handleInputChange}
                        className="block w-full px-4 py-2 border border-gray-300 rounded focus:outline-none"
                      >
                        <option value="">
                          {directAwardFilterLabels[filter]?.placeholder ||
                            'Selectează'}
                        </option>
                        {directAwardCounties.map((county) => (
                          <option key={county.value} value={county.value}>
                            {county.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <div key={filter}>
                      <label className="block text-gray-600 mb-2">
                        {directAwardFilterLabels[filter]?.label || filter}
                      </label>
                      <input
                        type={
                          filter.includes('Date')
                            ? 'date'
                            : filter.includes('Value')
                              ? 'number'
                              : 'text'
                        }
                        name={filter}
                        value={filters[filter]}
                        onChange={handleInputChange}
                        placeholder={
                          directAwardFilterLabels[filter]?.placeholder || ''
                        }
                        className="block w-full px-4 py-2 border border-gray-300 rounded focus:outline-none"
                      />
                    </div>
                  ),
                )}
              </div>
            )}
          </div>

          {/* 🔹 Butoane de Filtrare și Resetare */}
          <div className="shadow-xl rounded-lg p-5 border mt-10">
            <div className="flex items-center justify-end space-x-4 border-b pb-5">
              <button
                onClick={() => handleFilter()}
                className="bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-indigo-300"
              >
                Filtrează
              </button>
              <button
                onClick={handleReset}
                className="bg-red-100 hover:bg-red-300 text-gray-700 px-4 py-2 rounded-lg hover:text-white duration-300"
              >
                Resetează
              </button>
            </div>

            {/* 🔹 Listarea rezultatelor */}
            <div className="mt-8">
              {loading ? (
                <ListLoadingHolder />
              ) : (
                <AuctionList
                  auctions={results}
                  totalResults={totalResults}
                  pageSize={filters.pageSize}
                  pageIndex={filters.pageIndex}
                  onPageChange={(newPageIndex) => handleFilter(newPageIndex)}
                  auctionType={'DirectAwards'}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DirectAwards;
