const directAwardFilterLabels = {
  auctionId: { label: 'ID Achiziție Directă', placeholder: 'Ex: 123456' },
  auctionTitle: {
    label: 'Titlu Achiziție',
    placeholder: 'Ex: Achiziție materiale',
  },
  publishedDate: { label: 'Data Publicării', placeholder: 'Selectează data' },
  cpvCode: { label: 'Cod CPV', placeholder: 'Ex: 45210000' },
  authorityName: {
    label: 'Autoritate Contractantă',
    placeholder: 'Ex: Ministerul Educației',
  },
  authorityCUI: { label: 'CUI Autoritate', placeholder: 'Ex: 12345678' },
  city: { label: 'Oraș', placeholder: 'Ex: Cluj-Napoca' },
  county: { label: 'Județ', placeholder: 'Alege un județ' },
  minEstimatedValue: { label: 'Valoare Minimă', placeholder: 'Ex: 5000' },
  maxEstimatedValue: { label: 'Valoare Maximă', placeholder: 'Ex: 100000' },
  currency: { label: 'Monedă', placeholder: 'Ex: RON, EUR' },
};

export default directAwardFilterLabels;
