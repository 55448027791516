import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import './Header.css';
import logo from '../../assets/logo.png';
import ActiveLink from '../ActiveLink';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userName, setUserName] = useState(null);
  const navigate = useNavigate();

  // Verificare token și autentificare
  useEffect(() => {
    const checkAuth = () => {
      const token = Cookies.get('jwtToken');

      if (!token) {
        setUserName(null);
        return;
      }

      try {
        const decoded = jwtDecode(token);
        const exp = decoded.exp * 1000; // Convertim secunde în milisecunde
        const currentTime = Date.now();

        if (exp < currentTime) {
          // Token expirat -> ștergem și redirecționăm la login
          Cookies.remove('jwtToken');
          Cookies.remove('subscriptionType');
          Cookies.remove('userId');
          setUserName(null);
          navigate('/login');
        } else {
          setUserName(
            decoded[
              'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
            ] || 'User',
          );
        }
      } catch (error) {
        console.error('Eroare la decodificarea JWT:', error);
        Cookies.remove('jwtToken'); // Token invalid
        Cookies.remove('subscriptionType');
        Cookies.remove('userId');
        setUserName(null);
      }
    };

    checkAuth();
  }, [navigate]);

  // Toggle pentru meniul mobil
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Logout
  const handleLogout = () => {
    Cookies.remove('jwtToken');
    Cookies.remove('subscriptionType');
    Cookies.remove('userId');
    setUserName(null);
    navigate('/login');
  };

  // Redirecționare către contul utilizatorului
  const handleGoToAccount = () => {
    navigate('/account');
  };

  // Gestionare efectul scroll-ului
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setIsScrolled(currentScrollPos > prevScrollPos && currentScrollPos > 250);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <header
      className={`bg-white shadow-lg shadow-indigo-100 border-b border-slate-50 sticky top-0 w-full z-20 transition py-2 duration-300 ease-in-out ${
        isScrolled ? '-translate-y-full' : 'translate-y-0'
      }`}
    >
      <div className="container">
        <nav className="flex justify-between items-center">
          {/* Logo */}
          <figure>
            <Link to="/">
              <img src={logo} alt="Logo" className="w-24" />
            </Link>
          </figure>

          {/* Menu Desktop */}
          <ul className={`hidden lg:flex items-center gap-2`}>
            <ActiveLink to="/">Acasa</ActiveLink>
            <ActiveLink to="/proiecte">Proiecte</ActiveLink>
            <ActiveLink to="/licitatii">Licitații</ActiveLink>
            <ActiveLink to="/achizitii-directe">Achiziții Directe</ActiveLink>
            <ActiveLink to="/abonamente">Abonamente</ActiveLink>
            <ActiveLink to="/wiki">Wiki</ActiveLink>
            <ActiveLink to="/contact">Contact</ActiveLink>
          </ul>

          {/* Butoane autentificare */}
          <div className="flex items-center gap-5">
            {/* Meniu Mobil */}
            <button className="lg:hidden" onClick={toggleMenu}>
              <svg
                className="w-9 h-9 text-indigo-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>

            {userName ? (
              <div className="relative group w-fit">
                {/* Avatar utilizator */}
                <button className="w-14 h-14 bg-indigo-500 text-white text-xl font-bold flex items-center justify-center rounded-full">
                  {userName?.slice(0, 2)}
                </button>

                {/* Dropdown */}
                <div className="absolute top-full right-0 bg-white shadow-lg rounded-lg border border-gray-200 p-2 w-48 scale-y-0 group-hover:scale-y-100 origin-top duration-200">
                  <p className="text-center text-lg font-medium border-b pb-2 mb-2">
                    {userName}
                  </p>
                  <button
                    onClick={handleGoToAccount}
                    className="w-full text-left px-4 py-2 text-gray-700 hover:bg-indigo-100 hover:text-indigo-700 rounded-lg transition-colors"
                  >
                    Contul Meu
                  </button>
                  <button
                    onClick={handleLogout}
                    className="w-full text-left px-4 py-2 text-gray-700 hover:bg-red-100 hover:text-red-700 rounded-lg transition-colors mt-1"
                  >
                    Logout
                  </button>
                </div>
              </div>
            ) : (
              <Link
                to="/login"
                className="text-white bg-indigo-500 hover:bg-indigo-600 border border-indigo-500 focus:ring-4 focus:ring-indigo-300 font-medium rounded text-lg px-4 lg:px-6 py-2 lg:py-2 sm:mr-2 lg:mr-0 dark:bg-indigo-500 dark:hover:bg-indigo-700 focus:outline-none dark:focus:ring-indigo-800 shadow-lg shadow-indigo-400 hover:shadow-white duration-300"
              >
                Login
              </Link>
            )}
          </div>
        </nav>

        {/* Meniu Mobil */}
        {isMenuOpen && (
          <div className="mobile-menu">
            <Link to="/" className="mobile-link" onClick={toggleMenu}>
              Acasa
            </Link>
            <Link to="/proiecte" className="mobile-link" onClick={toggleMenu}>
              Proiecte
            </Link>
            <Link to="/licitatii" className="mobile-link" onClick={toggleMenu}>
              Licitații
            </Link>
            <Link
              to="/achizitii-directe"
              className="mobile-link"
              onClick={toggleMenu}
            >
              Achiziții Directe
            </Link>
            <Link to="/abonamente" className="mobile-link" onClick={toggleMenu}>
              Abonamente
            </Link>
            <Link to="/wiki" className="mobile-link" onClick={toggleMenu}>
              Wiki
            </Link>
            <Link to="/contact" className="mobile-link" onClick={toggleMenu}>
              Contact
            </Link>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
