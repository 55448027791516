import React, { useState } from 'react';
import SeoPageComponent from '../../components/SeoPageComponent/SeoPageComponent';
import Breadcrumbs from '../../components/Breadcrumbs';
import { contactForm } from '../../services/cmsService';
import contactPoint from '../../constants/contactPoint';

const Contact = () => {
  const breadcrumbLinks = [{ href: '/', label: 'Home' }, { label: 'Contact' }];

  const pageTitle = `Contact - ${process.env.REACT_APP_NAME} | Portal Achiziții Publice`;
  const pageDescription = `Contactează echipa ${process.env.REACT_APP_NAME} pentru suport, întrebări sau informații suplimentare despre achizițiile publice. Suntem aici să te ajutăm!`;
  const pageKeywords = `contact, suport, achiziții publice, licitații publice, Go4Projects, informații, întrebări, ajutor`;
  const pageUrl = `https://${process.env.REACT_APP_DOMAIN}/contact`;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'ContactPage',
    name: 'Contact - Go4Projects',
    url: pageUrl,
    description: pageDescription,
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+40 722 405 744',
      email: 'contact[at]go4projects[dot]com',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Calea Victoriei 155',
        addressLocality: 'București',
        postalCode: '010073',
        addressCountry: 'RO',
      },
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const validationErrors = {};
    if (!formData.name) validationErrors.name = 'Numele este obligatoriu.';
    if (!formData.email) {
      validationErrors.email = 'Email-ul este obligatoriu.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = 'Email-ul nu este valid.';
    }
    if (!formData.phone)
      validationErrors.phone = 'Numărul de telefon este obligatoriu.';
    if (!formData.message)
      validationErrors.message = 'Mesajul este obligatoriu.';
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await contactForm(formData);
        setIsSubmitted(true);
      } catch (error) {
        console.error('Eroare la trimiterea formularului:', error);
      }
    }
  };

  return (
    <>
      <SeoPageComponent
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageKeywords={pageKeywords}
        pageUrl={pageUrl}
      />
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>

      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />

      <div className="container my-20">
        {/* section title */}
        <div className="max-w-3xl mx-auto">
          <h2 className="text-center capitalize text-3xl md:text-5xl font-bold text-gray-700 mb-4 w-fit mx-auto relative after:absolute after:w-4/5 after:-bottom-4 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:border-b-4 after:border-indigo-500 after:border-double">
            Contact
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-16">
          {/* Informații de contact */}
          <div className="bg-white shadow-lg rounded-lg p-8 border hover:border-indigo-500 group duration-300">
            <h3 className="text-center text-4xl font-semibold mb-8 group-hover:text-indigo-500 duration-300">
              Informații de contact
            </h3>

            <p className="text-gray-600 mb-2">
              <strong>Companie:</strong>
              {' ' + contactPoint.companyName}
            </p>
            <p className="text-gray-600 mb-2">
              <strong>Adresă:</strong>
              {' ' + contactPoint.addressLine1}
            </p>
            <p className="text-gray-600 mb-2">
              <strong>Telefon:</strong> {contactPoint.phone}
            </p>
            <p className="text-gray-600 mb-8">
              <strong>Email:</strong> {contactPoint.displayEmail}
            </p>
            <iframe
              title="Harta sediului"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2848.1321347973726!2d26.084234876662016!3d44.45096170046983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b201ffd7e0a5b3%3A0xbe93261d0e8a951!2sBl.%20D1%2C%20Calea%20Victoriei%20155%2C%20Bucure%C8%99ti%20010073!5e0!3m2!1sen!2sro!4v1728641109678!5m2!1sen!2sro"
              width="100%"
              height="300"
              frameBorder="0"
              className="rounded-lg shadow-md"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>

          {/* Formular de contact */}
          <div className="bg-white shadow-lg rounded-lg p-8 border hover:border-indigo-500 group duration-300">
            <h3 className="text-center text-4xl font-semibold mb-8 group-hover:text-indigo-500 duration-300">
              Send message
            </h3>
            <form className="" onSubmit={handleSubmit}>
              {isSubmitted ? (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded">
                  Mesajul tău a fost trimis cu succes! Te vom contacta în cel
                  mai scurt timp posibil.
                </div>
              ) : (
                <>
                  <div className="flex flex-col md:flex-row items-center gap-4 mb-4">
                    <div className="w-full">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        Full Nume
                      </label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter your full name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`block w-full px-4 py-2 border ${
                          errors.name ? 'border-red-500' : 'border-gray-300'
                        } rounded-lg focus:outline-none`}
                      />
                      {errors.name && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.name}
                        </p>
                      )}
                    </div>

                    <div className="w-full">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        Telefon
                      </label>
                      <input
                        type="text"
                        name="phone"
                        placeholder="Enter Telefon number"
                        value={formData.phone}
                        onChange={handleChange}
                        className={`block w-full px-4 py-2 border ${
                          errors.phone ? 'border-red-500' : 'border-gray-300'
                        } rounded-lg focus:outline-none`}
                      />
                      {errors.phone && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.phone}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Email address
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter email address"
                      value={formData.email}
                      onChange={handleChange}
                      className={`block w-full px-4 py-2 border ${
                        errors.email ? 'border-red-500' : 'border-gray-300'
                      } rounded-lg focus:outline-none`}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.email}
                      </p>
                    )}
                  </div>

                  <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Message
                    </label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Write your message"
                      className={`block w-full px-4 py-2 border ${
                        errors.message ? 'border-red-500' : 'border-gray-300'
                      } rounded-lg focus:outline-none`}
                      rows="5"
                    ></textarea>
                    {errors.message && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.message}
                      </p>
                    )}
                  </div>
                  <div className="flex items-center justify-end">
                    <button
                      type="submit"
                      className="text-white bg-indigo-500 hover:bg-indigo-600 border border-indigo-500 focus:ring-4 focus:ring-indigo-300 font-medium rounded text-lg px-4 lg:px-6 py-2 lg:py-2 sm:mr-2 lg:mr-0 dark:bg-indigo-500 dark:hover:bg-indigo-700 focus:outline-none dark:focus:ring-indigo-800 hover:shadow-white duration-300"
                    >
                      Trimite Mesajul
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
