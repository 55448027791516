import React from 'react';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ItemLoadingHolder = () => {
    return (
        <div className='py-20'>
            <div className='container'>
                <div className='row'>
                    <div className="col-2-3 h-96 rounded bg-white shadow-lg p-5">
                        <Skeleton height={40} width="50%" className='mb-8' />
                        <Skeleton height={80} className='mb-3' />
                        <Skeleton height={50} width="60%" className='mb-3' />
                        <Skeleton height={50} width="90%" className='mb-3' />
                        <Skeleton height={20} width="40%" className='mb-3' />
                    </div>
                    <div className='col-1-3'>
                        <div className="rounded bg-white shadow-lg p-5">
                            <Skeleton height={30} width="70%" className='mb-8' />
                            <Skeleton height={36} className='mb-3' />
                            <Skeleton height={16} width="90%" className='mb-3' />
                            <Skeleton height={12} width="60%" className='mb-3' />
                        </div>
                    </div>
                    <div className='col-1-3'>
                        <div className="rounded bg-white shadow-lg p-5">
                            <Skeleton height={30} width="70%" className='mb-8' />
                            <Skeleton height={16} width="90%" className='mb-3' />
                            <Skeleton height={12} width="60%" className='mb-3' />
                        </div>
                    </div>
                    <div className='col-1-3'>
                        <div className="rounded bg-white shadow-lg p-5">
                            <Skeleton height={30} width="70%" className='mb-8' />
                            <Skeleton height={36} />
                        </div>
                    </div>
                    <div className='col-1-3'>
                        <div className="rounded bg-white shadow-lg p-5">
                            <Skeleton height={30} width="50%" className='mb-8' />
                            <Skeleton height={50} />
                        </div>
                    </div>

                    <div className="rounded bg-white shadow-lg p-5 w-full">
                        <Skeleton height={30} width="50%" className='mb-8' />
                        <Skeleton height={12} width="100%" className='mb-3' />
                        <Skeleton height={12} width="100%" className='mb-3' />
                        <Skeleton height={12} width="100%" className='mb-3' />
                        <Skeleton height={12} width="100%" className='mb-3' />
                        <Skeleton height={12} width="100%" className='mb-3' />
                        <Skeleton height={12} width="100%" className='mb-3' />
                        <Skeleton height={12} width="100%" className='mb-3' />
                    </div>

                    <div className="rounded bg-white shadow-lg p-5 w-full">
                        <Skeleton height={30} width="50%" className='mb-8' />
                        <Skeleton height={12} width="100%" className='mb-3' />
                        <Skeleton height={12} width="100%" className='mb-3' />
                        <Skeleton height={12} width="100%" className='mb-3' />
                        <Skeleton height={12} width="100%" className='mb-3' />
                        <Skeleton height={12} width="100%" className='mb-3' />
                        <Skeleton height={12} width="100%" className='mb-3' />
                        <Skeleton height={12} width="100%" className='mb-3' />
                        <Skeleton height={12} width="100%" className='mb-3' />
                    </div>

                    {/* <div className="rounded bg-white shadow-lg p-5 space-y-4">
                                <div className="h-7 max-w-64 rounded bg-blue-400 animate-pulse">
                                </div>
                                <div className="h-9 max-w-3xl rounded bg-gray-600 animate-pulse">
                                </div>
                                <div className="h-4 max-w-96 rounded bg-gray-400 animate-pulse"></div>
                                <div className="h-3 w-72 rounded bg-gray-400 animate-pulse"></div>
                            </div> */}
                </div>
            </div>
        </div>
    );
};

export default ItemLoadingHolder;