import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import {
  getUserInfo,
  updateUserCompanyInfo,
  updateUserContactInfo,
  changeUserPassword,
  updateEmailAlert,
} from '../../services/accountService';
import CountUp from 'react-countup';
import { IoAnalyticsOutline } from 'react-icons/io5';
import CompanyDetails from './CompanyDetails';
import UserDetails from './UserDetails';
import ChangePassword from './ChangePassword';
import EmailAlerts from './EmailAlerts';
import MySubscription from './MySubscription';

const UserAccount = (setIsAuthenticated) => {
  const [userData, setUserData] = useState({
    emailAlerts: false,
    alertTypes: [],
  });
  const [companyEdit, setCompanyEdit] = useState(false);
  const [contactEdit, setContactEdit] = useState(false);
  const [passwordEdit, setPasswordEdit] = useState(false);
  const [emailAlertsEdit, setEmailAlertsEdit] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const { success, error, user } = await getUserInfo();
      if (success) {
        console.log('Răspuns API:', user); // Loghează răspunsul pentru verificare
        setUserData({
          ...user,
          emailAlerts: user.userAlerts?.emailAlertsEnabled ?? false,
          alertTypes: user.userAlerts?.alertTypes ?? [],
        });
      } else {
        setError(
          error || 'A apărut o eroare la încărcarea datelor utilizatorului.',
        );
      }
    };

    fetchUserData();
  }, []);

  const handleCompanySave = async () => {
    const success = await updateUserCompanyInfo(userData);
    if (success) {
      setCompanyEdit(false);
      setSuccess('Informațiile despre companie au fost actualizate cu succes.');
    }
  };

  const handleContactSave = async () => {
    const success = await updateUserContactInfo(userData);
    if (success) {
      setContactEdit(false);
      setSuccess('Informațiile de contact au fost actualizate cu succes.');
    }
  };

  const handlePasswordSave = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const currentPassword = formData.get('currentPassword');
    const newPassword = formData.get('newPassword');
    const confirmNewPassword = formData.get('confirmNewPassword');

    if (newPassword !== confirmNewPassword) {
      setError('Parolele nu se potrivesc.');
      return;
    }

    try {
      const success = await changeUserPassword({
        currentPassword,
        newPassword,
      });

      if (success) {
        setPasswordEdit(false);
        setSuccess('Parola a fost schimbată cu succes.');
        setError('');
      } else {
        setError(
          'A apărut o eroare la schimbarea parolei. Verificați parola curentă.',
        );
      }
    } catch (err) {
      setError(err.message || 'O eroare neașteptată a apărut.');
    }
  };

  const handleEmailAlertsSave = async () => {
    const updateEmailAlertResponse = await updateEmailAlert(
      userData.emailAlerts,
      userData.alertTypes || [], // Asigură-te că trimite o listă, chiar dacă este goală
    );

    if (updateEmailAlertResponse.success) {
      setEmailAlertsEdit(false);
      setSuccess(
        'Preferințele pentru alertele pe email au fost actualizate cu succes.',
      );
    } else {
      // Afisează exact mesajul de eroare primit de la server
      setError(
        updateEmailAlertResponse.message ||
          'A apărut o eroare la actualizarea preferințelor pentru alertele pe email.',
      );
    }
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [success]);

  const breadcrumbLinks = [
    { href: '/', label: 'Home' },
    { label: 'User Account' },
  ];

  return (
    <>
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />

      <div className="py-20 bg-gray-100 relative">
        {success && (
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 mt-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded shadow-lg z-50">
            {success}
          </div>
        )}

        <div className="container grid grid-cols-1 gap-6">
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
              {error}
            </div>
          )}

          {/* Statistici */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-white shadow-lg shadow-gray/20 rounded-md px-3 group">
              <div className="flex items-center justify-between border-b border-indigo-500/40">
                <div className="w-16 h-16 bg-indigo-500 text-white flex items-center justify-center rounded-lg shadow-xl shadow-indigo-500/50 -mt-10 group-hover:-mt-14 duration-300">
                  <i className="fas fa-list text-4xl"></i>
                </div>
                <div className="text-right pt-1 pb-2">
                  <span className="text-indigo-500 text-4xl drop-shadow-xl font-semibold">
                    <CountUp duration={3} end={120} />
                  </span>
                  <h3 className="text-gray-800 tracking-wider drop-shadow-xl">
                    Licitații pe codurile mele
                  </h3>
                </div>
              </div>
              <p className="py-2 flex gap-2 line-clamp-1">
                <span className="text-indigo-500 flex gap-1">
                  +<CountUp duration={3} end={34} /> <IoAnalyticsOutline />
                </span>{' '}
                last week
              </p>
            </div>

            <div className="bg-white shadow-lg shadow-gray/20 rounded-md px-3 group">
              <div className="flex items-center justify-between border-b border-green-500/40">
                <div className="w-16 h-16 bg-green-500 text-white flex items-center justify-center rounded-lg shadow-xl shadow-green-500/50 -mt-10 group-hover:-mt-14 duration-300">
                  <i className="fas fa-database text-4xl"></i>
                </div>
                <div className="text-right pt-1 pb-2">
                  <span className="text-green-500 text-4xl drop-shadow-xl font-semibold">
                    <CountUp duration={3} end={15000} />
                  </span>
                  <h3 className="text-gray-800 tracking-wider drop-shadow-xl">
                    Licitații totale în sistem
                  </h3>
                </div>
              </div>
              <p className="py-2 flex gap-2 line-clamp-1">
                <span className="text-green-500 flex gap-1">
                  +<CountUp duration={3} end={12} /> <IoAnalyticsOutline />
                </span>{' '}
                last week
              </p>
            </div>

            <div className="bg-white shadow-lg shadow-gray/20 rounded-md px-3 group">
              <div className="flex items-center justify-between border-b border-yellow-500/40">
                <div className="w-16 h-16 bg-yellow-500 text-white flex items-center justify-center rounded-lg shadow-xl shadow-yellow-500/50 -mt-10 group-hover:-mt-14 duration-300">
                  <i className="fas fa-star text-4xl"></i>
                </div>
                <div className="text-right pt-1 pb-2">
                  <span className="text-yellow-500 text-4xl drop-shadow-xl font-semibold">
                    <CountUp duration={3} end={35} />
                  </span>
                  <h3 className="text-dark tracking-wider drop-shadow-xl">
                    Licitații urmărite
                  </h3>
                </div>
              </div>
              <p className="py-2 flex gap-2 line-clamp-1">
                <span className="text-yellow-500 flex gap-1">
                  +<CountUp duration={3} end={11} />
                  <IoAnalyticsOutline />
                </span>{' '}
                last week
              </p>
            </div>
          </div>

          <div className="py-6 grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* Date Companie */}
            <CompanyDetails
              userData={userData}
              setUserData={setUserData}
              companyEdit={companyEdit}
              setCompanyEdit={setCompanyEdit}
              handleCompanySave={handleCompanySave}
            />

            {/* Date Utilizator */}
            <UserDetails
              userData={userData}
              setUserData={setUserData}
              contactEdit={contactEdit}
              setContactEdit={setContactEdit}
              handleContactSave={handleContactSave}
            />

            {/* Schimbă Parola */}
            <ChangePassword
              passwordEdit={passwordEdit}
              setPasswordEdit={setPasswordEdit}
              handlePasswordSave={handlePasswordSave}
            />

            {/* Secțiune Alerte pe Email */}
            <EmailAlerts
              userData={userData}
              setUserData={setUserData}
              emailAlertsEdit={emailAlertsEdit}
              setEmailAlertsEdit={setEmailAlertsEdit}
              handleEmailAlertsSave={handleEmailAlertsSave}
            />
            <MySubscription
              setIsAuthenticated={setIsAuthenticated}
            ></MySubscription>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAccount;
