import { NavLink } from 'react-router-dom';

const ActiveLink = ({ to, children }) => {
    return (
        <NavLink to={to}
            className={({ isActive }) =>
                isActive
                    ? "bg-white shadow-lg shadow-indigo-200 text-indigo-500 font-medium border border-indigo-500 rounded px-6 py-1.5 duration-300"
                    : "bg-white hover:shadow-lg hover:shadow-indigo-200 hover:text-indigo-500 font-medium border border-transparent hover:border-indigo-500 px-6 py-1.5 rounded duration-300"
            }
        >
            {children}
        </NavLink>
    );
};

export default ActiveLink;