import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BASE_URL + '/api';

// 🔎 1. Fetch lista de proiecte (cu filtre paginare, titlu, autoritate etc.)
export const fetchProjects = async (filters) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/projects/search`,
      filters,
    );
    return response.data;
  } catch (error) {
    console.error('Eroare la fetchProjects:', error);
    throw error;
  }
};

// 📄 2. Fetch proiect individual după ID
export const getProjectById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/projects/${id}`);
    return response.data;
  } catch (error) {
    console.error('Eroare la getProjectById:', error);
    throw error;
  }
};
