import React, { useState } from 'react';
import { forgotPassword } from '../../services/accountService';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await forgotPassword({
        email,
        clientUrl: window.location.origin,
      });

      setMessage(response.message);
      setSuccess(response.success);
    } catch (err) {
      setMessage('O eroare neașteptată a apărut.');
      setSuccess(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 px-4">
      <div className="max-w-md w-full bg-white shadow-md rounded px-8 py-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          Recuperare Parolă
        </h2>
        <p className="text-sm text-gray-600 mb-6">
          Introduceți adresa de email asociată contului dvs. pentru a primi
          instrucțiuni de resetare.
        </p>
        {message && (
          <div
            className={`p-3 mb-4 text-sm rounded ${
              success
                ? 'bg-green-100 text-green-700 border border-green-500'
                : 'bg-red-100 text-red-700 border border-red-500'
            }`}
          >
            {message}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Adresa de email
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="email@example.com"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Trimite
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
