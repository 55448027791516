import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const SeoPageComponent = ({
  pageTitle,
  pageDescription,
  pageUrl,
  logoUrl,
  keywords,
  canonicalUrl = null, // Parametru opțional, implicit NULL
  author = 'Go4Projects',
  publisher = 'Go4Projects',
  language = 'ro',
  region = 'RO', // România
  placename = 'București',
  latitude = '44.4268',
  longitude = '26.1025',
}) => {
  if (!HelmetProvider) {
    console.error(
      'HelmetProvider is missing. Ensure it wraps your application.',
    );
    return null;
  }

  const finalCanonical = canonicalUrl || pageUrl;

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={keywords} />
      <meta name="robots" content="index, follow" />
      <meta name="author" content={author} />
      <meta name="publisher" content={publisher} />
      <meta name="language" content={language} />
      <meta name="geo.region" content={region} />
      <meta name="geo.placename" content={placename} />
      <meta name="geo.position" content={`${latitude};${longitude}`} />
      <meta name="ICBM" content={`${latitude}, ${longitude}`} />

      {/* Canonical URL */}
      <link rel="canonical" href={finalCanonical} />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:image" content={logoUrl} />
      <meta property="og:type" content="website" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content={logoUrl} />
    </Helmet>
  );
};

export default SeoPageComponent;
