import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
  Cell,
} from 'recharts';

const GanttChart = ({ procedures }) => {
  const [selected, setSelected] = useState(null);

  if (!procedures || procedures.length === 0) {
    return (
      <p className="text-sm text-gray-500 italic">
        Nu există proceduri pentru a genera graficul.
      </p>
    );
  }

  const getDays = (start, end) =>
    Math.floor((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));

  const projectStart = new Date(procedures[0].startDate);

  const data = procedures.map((proc, index) => {
    const start = new Date(proc.startDate);
    const end = new Date(proc.endDate);
    const offset = getDays(projectStart, start);
    const duration = getDays(start, end) || 1;

    return {
      name: proc.procedureTitle,
      offset,
      duration,
      label: `${proc.startDate} → ${proc.endDate}`,
    };
  });

  const totalDays = Math.max(...data.map((d) => d.offset + d.duration)) + 1;

  const handleBarClick = (data, index) => {
    setSelected(data);
  };

  return (
    <div className="w-full">
      <ResponsiveContainer width="100%" height={data.length * 45}>
        <BarChart
          layout="vertical"
          data={data}
          margin={{ top: 10, right: 20, left: 0, bottom: 10 }}
          barCategoryGap={10}
        >
          <XAxis
            type="number"
            domain={[0, totalDays]}
            tickFormatter={(val) => `Ziua ${val}`}
            stroke="#9ca3af"
            tick={{ fontSize: 11 }}
          />
          <YAxis
            dataKey="name"
            type="category"
            width={200}
            tick={{ fontSize: 13, fill: '#374151' }}
            stroke="#9ca3af"
          />
          <Bar dataKey="offset" stackId="gantt" fill="#f3f4f6" />
          <Bar dataKey="duration" stackId="gantt" onClick={handleBarClick}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                cursor="pointer"
                fill={selected?.name === entry.name ? '#4338ca' : '#6366f1'}
              />
            ))}
            <LabelList
              dataKey="label"
              position="right"
              style={{ fill: '#374151', fontSize: 11 }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      {selected && (
        <div className="mt-4 p-4 bg-gray-50 border border-gray-200 rounded shadow-sm">
          <h3 className="font-semibold text-gray-800 mb-1">{selected.name}</h3>
          <p className="text-sm text-gray-600 italic">{selected.label}</p>
        </div>
      )}
    </div>
  );
};

export default GanttChart;
